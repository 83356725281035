import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import MainApp from './MainApp';
import BootstrapForm from './admin/pages/BootstrapForm';
import AdminAPI from './api/AdminAPI';
import { Spinner, Center } from '@chakra-ui/react';
import InternalServerError from './admin/pages/InternalServerError';

const BootstrapCheck = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isBootstrapped, setIsBootstrapped] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const checkBootstrap = async () => {
    try {
      const response = await AdminAPI.GetBootstrapStatus();
      if (response.isBootstrapped) {
        setIsBootstrapped(true);
      }
    } catch (error: any) {
      const status = error.response?.status;
      if (status === 500) {
        setHasServerError(true);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to check bootstrap status',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkBootstrap();
  }, []);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (hasServerError) {
    return <InternalServerError />;
  }

  if (isBootstrapped) {
    return <MainApp />;
  }

  return <BootstrapForm onSuccess={checkBootstrap} />;
};

export default BootstrapCheck;
