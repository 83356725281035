import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';

const OnboardingDisabled = () => {
  const { signoutRedirect } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = () => {
    setIsLoggingOut(true);
    signoutRedirect();
  };

  return (
    <Container maxW="container.sm" py={20}>
      <VStack spacing={8} align="center" textAlign="center">
        <Icon as={LockClosedIcon} boxSize={12} color="gray.400" />

        <VStack spacing={4}>
          <Heading size="lg">Organization Access Required</Heading>
          <Text fontSize="lg" maxW="480px">
            To use ValidMind, you need to be invited to an organization by an
            administrator.
          </Text>
        </VStack>

        <Box
          p={6}
          bg="gray.50"
          _dark={{ bg: 'gray.800' }}
          rounded="lg"
          maxW="480px"
        >
          <Text color="gray.600" _dark={{ color: 'gray.400' }}>
            Please contact your organization administrator to request access. If
            you're not sure who to contact, reach out to our support team at{' '}
            <Text as="span" color="brand.500" _dark={{ color: 'brand.300' }}>
              support@validmind.ai
            </Text>
          </Text>
        </Box>

        <Button
          size="lg"
          colorScheme="brand"
          onClick={handleLogout}
          isLoading={isLoggingOut}
          isDisabled={isLoggingOut}
        >
          Back to Login
        </Button>
      </VStack>
    </Container>
  );
};

export default OnboardingDisabled;
