import { Button, FormControl, HStack, Stack, VStack } from '@chakra-ui/react';
import AddFindingModal from '../AddFindingModal';
import { DownloadValidationReportModal } from '../DownloadModal';
import AttributesRail from '../Layout/AttributesRail';
import { Label } from '../Layout';
import DownloadOfflineDocument from '../DownloadOfflineDocument';
import MoreInfoPopOver from '../MoreInfoPopOver';
import { TInventoryModel } from '../../models/inventory_model';
import { CONFIG } from '../../config';

interface Props {
  inventoryModel: TInventoryModel;
  onOpenTemplateModal?: () => void;
}

export default function AttributesRailValidationReportOverview({
  inventoryModel,
  onOpenTemplateModal,
}: Props) {
  return (
    <AttributesRail>
      <VStack align="start">
        <Stack>
          <AddFindingModal triggerLabel="Add Finding" />

          {!inventoryModel.template_vr.is_offline ? (
            <DownloadValidationReportModal inventoryModel={inventoryModel} />
          ) : (
            <Stack>
              <DownloadOfflineDocument
                files={inventoryModel.template_vr.offline_files}
              />
            </Stack>
          )}
        </Stack>
      </VStack>

      {onOpenTemplateModal && (
        <VStack align="start">
          <FormControl role="group">
            <HStack gap={0}>
              <Label>VALIDATION REPORT TEMPLATE</Label>
              <MoreInfoPopOver
                title="Validation Report Templates"
                description="Customizable documentation templates offer a standardized approach to creating consistent and comprehensive validation reports."
                link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-documentation/working-with-documentation-templates.html`}
                placement="right-end"
                iconProps={{
                  ml: 2,
                  fill: 'brand.base',
                  opacity: 0,
                  _groupHover: { opacity: 1 },
                }}
              />
            </HStack>
            <Button
              variant={'link'}
              onClick={onOpenTemplateModal}
              whiteSpace={'normal'}
              textAlign={'left'}
              data-testid="view-validation-report-template-btn"
            >{`${inventoryModel.template_vr.name} v${inventoryModel.template_vr.version_number}`}</Button>
          </FormControl>
        </VStack>
      )}
    </AttributesRail>
  );
}
