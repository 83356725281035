import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  FormControl,
  GridItem,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { displayFormatedDateAndTime } from '../../utils';
import ModelsUsingThisTemplate from '../ModelsUsingThisTemplate';
import { DiffEditor, Editor } from '@monaco-editor/react';
import { stringify } from 'yaml';
import { LoadingContainer } from '../LoadingContainer';
import { Label } from '../Layout';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { StakeholderItem } from '../../pages/ModelInventory/InventoryModel/Overview';
import { Template } from '../../models';
import {
  addIndexNumbersToSections,
  convertToSectionTree,
  TemplateSectionTree,
  TemplateVersion,
} from '../../models/template';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../api/API';
import { useContext, useEffect, useMemo, useState } from 'react';
import { canUpdateDocument } from '../../auth/utils';
import { UsersContext } from '../../contexts';
import MoreInfoPopOver from '../MoreInfoPopOver';
import { TInventoryModel } from '../../models/inventory_model';
import { CONFIG } from '../../config';

const editorOptions = {
  fontSize: 14,
  folding: true,
  readOnly: true,
  lineHeight: 18,
  lineNumbers: false,
  contextmenu: true,
  automaticLayout: true,
  roundedSelection: true,
  fontFamily: 'monospace',
  selectOnLineNumbers: true,
  hideCursorInOverviewRuler: true,
  minimap: {
    enabled: false,
  },
  scrollbar: {
    horizontalSliderSize: 16,
    verticalSliderSize: 16,
  },
  autoIndent: 'full',
  detectIndentation: true,
  tabSize: 1,
  matchBrackets: 'always',
};

interface Props {
  inventoryModel: TInventoryModel;
  isOpen: boolean;
  activeTemplateDocumentType:
    | 'model_documentation'
    | 'validation_report'
    | 'monitoring'
    | string;
  onClose: () => void;
}
export default function TemplateModal({
  inventoryModel,
  isOpen,
  activeTemplateDocumentType,
  onClose,
}: Props) {
  // 1. Context hooks
  const { currentUser } = useContext(UsersContext);

  // 2. Other hooks
  const queryClient = useQueryClient();
  const toast = useToast();

  // 3. State hooks
  const [swappingTemplateNotes, setSwappingTemplateNotes] = useState('');
  const [isViewingSingleTemplate, setIsViewingSingleTemplate] = useState(true);
  const [isPreparingSwap, setIsPreparingSwap] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<Template>();
  const [activeTemplateTree, setActiveTemplateTree] =
    useState<TemplateSectionTree[]>();
  const [activeTemplateToSwap, setActiveTemplateToSwap] = useState<Template>();
  const [activeTemplateVersionToSwap, setActiveTemplateVersionToSwap] =
    useState<TemplateVersion>();
  const [activeTemplateTreeToSwap, setActiveTemplateTreeToSwap] =
    useState<TemplateSectionTree[]>();
  const [availableTemplates, setAvailableTemplates] = useState<Template[]>([]);

  // 4. Memo hooks
  const userCanUpdateDocument = useMemo(
    () =>
      canUpdateDocument(
        currentUser,
        inventoryModel,
        activeTemplateDocumentType,
        true,
      ),
    [currentUser, inventoryModel, activeTemplateDocumentType],
  );

  // 5. Query/Mutation hooks
  const fetchTemplatesToSwap = useQuery(
    ['templates'],
    async () => {
      // Currently fetching only base templates, not copies of the
      // base template which are generated when a user inserts/removes
      // content blocks directly in documentation
      return await API.GetTemplates();
    },
    {
      enabled: isOpen,
      onSuccess: (templates_: Template[]) => {
        const filteredTemplates = templates_.filter(
          t => t.type === activeTemplateDocumentType,
        );
        const sortedTemplates = filteredTemplates.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setAvailableTemplates(sortedTemplates);

        // Set default template to swap to the current version
        // of the active template, otherwise pick the first
        // one in the list
        const current = templates_.find(
          t => t.cuid === activeTemplate?.template_cuid,
        );

        setActiveTemplateToSwap(current || sortedTemplates[0]);
      },
    },
  );

  const templateVersionToSwapQuery = useMutation(
    ['templates', activeTemplateToSwap?.cuid, 'versions'],
    async ({ versionToSwap }: { versionToSwap?: TemplateVersion }) => {
      return await API.GetTemplateVersion(
        activeTemplateToSwap?.cuid!,
        versionToSwap?.version_number || 'latest',
      );
    },
    {
      onSuccess: (template_: Template) => {
        const sections = template_.current_version?.json.sections || [];
        const sectionTree = convertToSectionTree(sections);

        const startIndex =
          typeof template_?.template?.start_index === 'number'
            ? template_.template.start_index
            : 1;
        addIndexNumbersToSections(sectionTree, {}, '', startIndex);
        setActiveTemplateTreeToSwap(sectionTree);
      },
    },
  );

  const swapTemplate = useMutation(
    [],
    async () => {
      return await API.SwapTemplate(
        inventoryModel,
        activeTemplateToSwap?.cuid!,
        activeTemplateVersionToSwap?.version_number || 'latest',
        swappingTemplateNotes,
        activeTemplateDocumentType as
          | 'model_documentation'
          | 'validation_report'
          | 'monitoring',
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['templates']);
        queryClient.invalidateQueries(['inventory-model', inventoryModel.cuid]);

        onModalClose();

        const docName = {
          model_documentation: 'model documentation',
          validation_report: 'validation report',
          monitoring: 'monitoring',
        }[activeTemplateDocumentType];

        toast({
          title: 'Great!',
          description: `Your ${docName} template has been swapped successfully.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'There was an error swapping the template.',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
    },
  );

  // 6. Effects
  useEffect(() => {
    if (activeTemplateVersionToSwap) {
      templateVersionToSwapQuery.mutate({
        versionToSwap: activeTemplateVersionToSwap,
      });
    }
  }, [activeTemplateVersionToSwap]);

  useEffect(() => {
    if (activeTemplateDocumentType?.length > 0) {
      const dbTemplate = {
        model_documentation: inventoryModel?.template,
        validation_report: inventoryModel?.template_vr,
        monitoring: inventoryModel?.template_monitoring,
      }[activeTemplateDocumentType];

      const startIndex = dbTemplate!.template.start_index;
      const sectionTree = convertToSectionTree(dbTemplate!.template.sections);

      addIndexNumbersToSections(sectionTree, {}, '', startIndex);
      setActiveTemplate(dbTemplate);
      setActiveTemplateTree(sectionTree);
    }
  }, [activeTemplateDocumentType]);

  useEffect(() => {
    if (activeTemplateToSwap && activeTemplateToSwap.versions) {
      if (activeTemplate?.template_cuid === activeTemplateToSwap.cuid) {
        // set the current version of the inventory model's template
        const currentVersion = activeTemplateToSwap?.versions?.find(
          v => v.version_number === activeTemplate?.version_number,
        );
        setActiveTemplateVersionToSwap(currentVersion);
      } else {
        // not the inventory models's template? set the latest version of the selected template
        setActiveTemplateVersionToSwap(activeTemplateToSwap.versions[0]);
      }
    }
  }, [activeTemplateToSwap]);

  const onSwapTemplate = () => {
    swapTemplate.mutateAsync();
  };
  const onCompareTemplate = () => {
    setIsViewingSingleTemplate(false);
  };

  const onCancelSwap = () => {
    setIsViewingSingleTemplate(true);
  };

  const onCancelPrepare = () => {
    setIsPreparingSwap(false);
  };

  const onPrepareSwap = () => {
    setIsPreparingSwap(true);
  };

  const onModalClose = () => {
    setIsViewingSingleTemplate(true);
    setIsPreparingSwap(false);
    setSwappingTemplateNotes('');
    setActiveTemplateToSwap(undefined);
    setActiveTemplateVersionToSwap(undefined);
    onClose();
  };

  const Subtitle = ({ children }: any) => {
    return (
      <Text fontSize={'lg'} fontWeight={'bold'} mb={4}>
        {children}
      </Text>
    );
  };

  const title = {
    model_documentation: 'Model Template',
    validation_report: 'Validation Template',
    monitoring: 'Monitoring Template',
  }[activeTemplateDocumentType];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      size={'full'}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent margin={16}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton data-testid="close-template-modal-btn" />
        <ModalBody display={'grid'} data-test-id="active-template-modal">
          <SimpleGrid
            mb={4}
            spacing={4}
            templateRows={'1fr'}
            templateColumns={
              isViewingSingleTemplate ? '240px 1fr ' : '240px 1fr 240px'
            }
          >
            <GridItem>
              <VStack spacing={8} alignItems={'stretch'}>
                <HStack gap={0}>
                  <Heading as={'h5'}>Active Template</Heading>
                  <MoreInfoPopOver
                    title="Swap Templates"
                    description="Swap between different versions of your model documentation or validation report templates. Switch to a completely different template, or apply another version of your current template."
                    link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-documentation/swap-documentation-templates.html`}
                    placement="right-end"
                    iconProps={{
                      ml: 2,
                      fill: 'brand.base',
                    }}
                  />
                </HStack>
                <Box>
                  <Label mb={2}>NAME</Label>
                  {activeTemplate?.name}
                </Box>
                <Box>
                  <Label mb={2}>VERSION</Label>
                  {activeTemplate?.version_number}
                </Box>
                <Box>
                  <Label mb={2}>DATE CREATED</Label>
                  <Badge px={'2'} py={'0.5'} rounded={'md'}>
                    {activeTemplate &&
                      activeTemplate.created_at &&
                      displayFormatedDateAndTime(activeTemplate.created_at)}
                  </Badge>
                </Box>
                <Box>
                  <Label mb={2}>CREATED BY</Label>

                  {activeTemplate &&
                  activeTemplate.template_version_by &&
                  activeTemplate.template_version_by.name ? (
                    <StakeholderItem
                      key={activeTemplate.template_version_by.cuid}
                      user={{
                        name: activeTemplate.template_version_by.name,
                        email: activeTemplate.template_version_by.email,
                        picture: activeTemplate.template_version_by.picture,
                      }}
                    />
                  ) : (
                    'ValidMind'
                  )}
                </Box>
                <Box>
                  <Label mb={2}>NOTES</Label>
                  <Text>{activeTemplate?.template_version_description}</Text>
                </Box>
                {/* <hr />
                <Box>
                  {activeTemplate && activeTemplate.template_cuid && (
                    <ModelsUsingThisTemplate
                      selectedTemplateId={activeTemplate?.template_cuid}
                    />
                  )}
                </Box> */}
              </VStack>
            </GridItem>

            <GridItem>
              <Box height={'full'} overflow={'hidden'} borderRadius={'md'}>
                {isViewingSingleTemplate ? (
                  <Editor
                    defaultLanguage="yaml"
                    defaultValue={stringify(activeTemplateTree || {})}
                    options={editorOptions}
                    theme="vs-dark"
                  />
                ) : (
                  <DiffEditor
                    original={stringify(activeTemplateTree || {})}
                    modified={stringify(activeTemplateTreeToSwap || {})}
                    language="yaml"
                    theme="vs-dark"
                    options={editorOptions}
                  />
                )}
              </Box>
            </GridItem>

            <GridItem hidden={isViewingSingleTemplate}>
              <VStack spacing={8} alignItems={'stretch'}>
                <Heading as={'h5'}>Swap to Template</Heading>
                {/*

                      Template will be set by default to the Active Template assuming
                      that the user will want to change the version of the same template.

                  */}

                <LoadingContainer isLoading={fetchTemplatesToSwap.isLoading}>
                  <Box>
                    <Label mb={2}>TEMPLATE</Label>

                    <Select
                      value={activeTemplateToSwap?.cuid}
                      onChange={e => {
                        setActiveTemplateVersionToSwap(undefined);
                        setActiveTemplateToSwap(
                          availableTemplates.find(
                            t => t.cuid === e.target.value,
                          ),
                        );
                      }}
                    >
                      {availableTemplates.map(t => (
                        <option value={t.cuid}>{t.name}</option>
                      ))}
                    </Select>
                  </Box>
                </LoadingContainer>

                {/*

                  Template details will load once the user selects a template from the
                  dropdown above.

*/}

                <LoadingContainer isLoading={fetchTemplatesToSwap.isLoading}>
                  <Box>
                    <Label mb={2}>VERSION</Label>
                    <Select
                      value={activeTemplateVersionToSwap?.version_number}
                      onChange={e => {
                        setActiveTemplateVersionToSwap(
                          activeTemplateToSwap?.versions?.find(
                            v => v.version_number.toString() === e.target.value,
                          ),
                        );
                      }}
                    >
                      <option value="">Select a version to swap to</option>
                      {activeTemplateToSwap?.versions?.map(v => (
                        <option value={v.version_number}>
                          {v.version_number} - {v.description}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <LoadingContainer
                    isLoading={templateVersionToSwapQuery.isLoading}
                  >
                    <Box>
                      <Label mb={2}>DATE CREATED</Label>
                      <Badge px={'2'} py={'0.5'} rounded={'md'}>
                        {activeTemplateVersionToSwap &&
                          activeTemplateVersionToSwap.created_at &&
                          displayFormatedDateAndTime(
                            activeTemplateVersionToSwap.created_at,
                          )}
                      </Badge>
                    </Box>
                    <Box>
                      <Label mb={2}>CREATED BY</Label>

                      {activeTemplateVersionToSwap &&
                      activeTemplateVersionToSwap.created_by &&
                      activeTemplateVersionToSwap.created_by.name ? (
                        <StakeholderItem
                          key={activeTemplateVersionToSwap.created_by.cuid}
                          user={{
                            name: activeTemplateVersionToSwap.created_by.name,
                            email: activeTemplateVersionToSwap.created_by.email,
                            picture:
                              activeTemplateVersionToSwap.created_by.picture,
                          }}
                        />
                      ) : (
                        'ValidMind'
                      )}
                    </Box>
                    <Box>
                      <Label mb={2}>NOTES</Label>
                      <Text>{activeTemplateVersionToSwap?.description}</Text>
                    </Box>
                    <hr />
                    <Box>
                      {activeTemplateToSwap && (
                        <ModelsUsingThisTemplate
                          selectedTemplateId={activeTemplateToSwap?.cuid}
                        />
                      )}
                    </Box>
                  </LoadingContainer>
                </LoadingContainer>
              </VStack>
            </GridItem>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter justifyContent={'flex-start'}>
          <HStack hidden={!isViewingSingleTemplate || !userCanUpdateDocument}>
            <Button
              leftIcon={<Icon as={ArrowsRightLeftIcon} />}
              onClick={onCompareTemplate}
            >
              Swap Template
            </Button>
          </HStack>
          <HStack
            hidden={isViewingSingleTemplate || isPreparingSwap}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Button onClick={onCancelSwap}>Cancel</Button>

            <Button
              disabled={fetchTemplatesToSwap.isLoading}
              onClick={onPrepareSwap}
            >
              Prepare Swap
            </Button>
          </HStack>
          <Stack hidden={!isPreparingSwap} width={'100%'} alignItems={'center'}>
            <VStack width={'calc(100% - 480px - 2rem)'}>
              <Alert status="info" variant={'left-accent'} mb={4}>
                <AlertIcon alignSelf={'flex-start'} />
                <VStack alignItems={'flex-start'}>
                  <AlertTitle mr={2}>About swapping templates</AlertTitle>
                  <AlertDescription>
                    When swapping templates only the document structure is
                    changed, any modifications that you might have done to any
                    content block will remain inside each block. <br /> If you
                    added a Text Block to your old template and want to reuse
                    it's contents, simply switch back to the old template and
                    the contents should be there for you to copy.
                  </AlertDescription>
                </VStack>
              </Alert>
              <FormControl>
                <Label mb={2}>SWAPPING TEMPLATE NOTES </Label>
                <Textarea
                  value={swappingTemplateNotes}
                  onChange={e => setSwappingTemplateNotes(e.target.value)}
                  placeholder="Add notes about why you are swapping this document's template"
                />
              </FormControl>
              <HStack width={'100%'} justifyContent={'space-between'}>
                <Button
                  onClick={onCancelPrepare}
                  disabled={swapTemplate.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onSwapTemplate}
                  disabled={swappingTemplateNotes === ''}
                  isLoading={swapTemplate.isLoading}
                >
                  Swap Template
                </Button>
              </HStack>
            </VStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
