import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

const app_env = (window as any)._env_ || {};

const apiHostMapping = {
  'app.dev.vm.validmind.ai': 'https://api.dev.vm.validmind.ai/api/v1',
  'app.staging.validmind.ai': 'https://api.staging.validmind.ai/api/v1',
  'app.prod.validmind.ai': 'https://api.prod.validmind.ai/api/v1',
  'app.ca1.validmind.ai': 'https://api.ca1.validmind.ai/api/v1',
};

// Follow the window origin for any other case
// host does not have the http/https prefix so we use it
// to check the apiHostMapping
const host = window.location.host;
const origin = window.location.origin;

// Helper function to check if frontend dependencies should be enabled.
// To be extra-safe, we check for the host instead of APP_ENV in case the
// deployment process did not set the APP_ENV correctly.
//
// The dependencies can be explicitly disabled by setting
// ENABLE_FRONTEND_DEPENDENCIES=false in local development.
export const areFrontendDepsEnabled = () => {
  // If explicitly disabled, respect that even in local development
  if (app_env.ENABLE_FRONTEND_DEPENDENCIES === 'false') {
    return false;
  }

  // Assume local development will have frontend dependencies enabled
  const isLocalHost =
    host.includes('localhost') || host.startsWith('127.0.0.1');
  return isLocalHost || app_env.ENABLE_FRONTEND_DEPENDENCIES === 'true';
};

export const API_URL =
  (apiHostMapping[host as keyof object] as string) ||
  app_env.REACT_APP_API_URL ||
  `${origin}/api/v1`;

export const CONFIG = {
  REACT_APP_API_URL: API_URL,
  REACT_APP_HEAP_ENV_ID: app_env.REACT_APP_HEAP_ENV_ID || '3544910167',
  APP_ENV: app_env.APP_ENV || 'development',
  VM_SITE: app_env.VM_SITE || 'localdev',
  SENTRY_RELEASE: app_env.SENTRY_RELEASE || 'dev',
  SDK_PIP_INSTALL_COMMAND:
    app_env.SDK_PIP_INSTALL_COMMAND || 'pip install validmind',
  ENABLE_MONITORING_MOCKUP: app_env.ENABLE_MONITORING_MOCKUP || false,
  ENABLE_FRONTEND_DEPENDENCIES: app_env.ENABLE_FRONTEND_DEPENDENCIES === 'true',
  REACT_APP_CKEDITOR_LICENSE_KEY:
    app_env.REACT_APP_CKEDITOR_LICENSE_KEY ||
    'RkxoNWdNYTBkVFRhY2t3YjdoY2VlUWsvTitVWnVEc0w4ZW9KcTJENjZkSFJMbGlSeDN0ellOMUhXQT09LU1qQXlOVEEzTVRRPQ==',
  REACT_APP_LD_CLIENT_ID:
    app_env.REACT_APP_LD_CLIENT_ID || '65bd6e8c67730c1092a95048',
  USE_LAUNCHDARKLY: app_env.USE_LAUNCHDARKLY
    ? app_env.USE_LAUNCHDARKLY === 'true'
    : true,
  SPRIG_ENVIRONMENT_ID: app_env.SPRIG_ENVIRONMENT_ID || 'iOcLt06JSdew',

  AUTH_AUDIENCE:
    app_env.REACT_APP_AUTH_AUDIENCE || 'https://api.dev.vm.validmind.ai',
  AUTH_AUTHORITY:
    app_env.REACT_APP_AUTH_AUTHORITY || 'https://login.dev.vm.validmind.ai',
  AUTH_CLIENT_ID:
    app_env.REACT_APP_AUTH_CLIENT_ID || 'iHpItBjLturosPqnrIFT5S7HbX1IeIUS',
  AUTH_REDIRECT_URI: origin,
  AUTH_POST_LOGOUT_REDIRECT_URI: origin,
  AUTH_RESPONSE_TYPE: 'code',
  AUTH_SCOPE: app_env.REACT_APP_AUTH_SCOPE || 'openid profile email',
  AUTH_LOAD_USER_INFO: true,

  // Configurable location for ValidMind Docs
  VALIDMIND_DOCS_URL:
    app_env.REACT_APP_VALIDMIND_DOCS_URL || 'https://docs.validmind.ai',
};

export const oidcConfig: AuthProviderProps = {
  authority: CONFIG.AUTH_AUTHORITY,
  client_id: CONFIG.AUTH_CLIENT_ID,
  redirect_uri: CONFIG.AUTH_REDIRECT_URI,
  post_logout_redirect_uri: CONFIG.AUTH_POST_LOGOUT_REDIRECT_URI,
  response_type: CONFIG.AUTH_RESPONSE_TYPE,
  scope: CONFIG.AUTH_SCOPE,
  loadUserInfo: CONFIG.AUTH_LOAD_USER_INFO,
  extraQueryParams: {
    audience: CONFIG.AUTH_AUDIENCE,
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
