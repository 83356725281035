import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Badge,
  Spinner,
  useColorModeValue,
  Button,
  Icon,
  Flex,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { useOrganizationDefaults } from '../../hooks/admin';
import { PlusIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import AdminAPI from '../../api/AdminAPI';

export type UploadFileButtonProps = {
  onClick: (e: React.MouseEvent) => void;
};

export const UploadFileButton = ({ onClick }: UploadFileButtonProps) => {
  return (
    <Button
      onClick={onClick}
      leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
      variant="primary"
    >
      Upload New Organization Template
    </Button>
  );
};

function OrganizationDefaults() {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const fileDownloadRef = useRef<HTMLAnchorElement | null>(null);
  const toast = useToast();
  const { defaults, isLoading } = useOrganizationDefaults();
  const cardBg = useColorModeValue('white', 'gray.800');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloading, setDownloading] = useState(false);

  const uploadDefaultOrganizationTemplateMutation = useMutation({
    mutationFn: async (file: File) => {
      return AdminAPI.UploadDefaultOrganizationTemplate(file);
    },
    onSuccess: () => {
      toast({
        title: 'Successfully uploaded new organization template',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: 'Error uploading new organization template',
        description:
          error.response?.data?.message ||
          'Failed to upload organization template',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const getFileExtension = (file: File) => {
    return file.name.split('.').pop();
  };

  const uploadFiles = async (file: File) => {
    uploadDefaultOrganizationTemplateMutation.mutate(file);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const targetFile = e.target.files[0];

      if (getFileExtension(targetFile) !== 'json') {
        toast({
          title: 'Invalid file extension',
          description: 'The file you are trying upload is an invalid format.',
          status: 'warning',
          duration: null,
          isClosable: true,
          containerStyle: {
            whiteSpace: 'pre-line',
          },
          variant: 'subtle',
        });
      } else {
        // Trigger API upload
        await uploadFiles(targetFile);
      }
    }
  };

  const downloadDefaultOrganizationTemplate = async () => {
    try {
      setDownloading(true);
      let response;
      response = await AdminAPI.GetDefaultOrganizationTemplate();

      const blobUrl = URL.createObjectURL(new Blob([response]));
      setDownloadUrl(blobUrl);

      // fileDownloadRef.current?.click(); can run before downloadFilename new state is ready
      setTimeout(() => {
        fileDownloadRef.current?.click();
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (e) {
      toast({
        title: 'Error downloading current organization template',
        description: 'Failed to download organization template',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloading(false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="400px">
        <Spinner size="xl" color="brand.500" />
      </Box>
    );
  }

  return (
    <VStack spacing={8} align="stretch">
      <input
        ref={fileUploadRef}
        type="file"
        hidden
        onChange={handleFileUpload}
      />
      <Flex justify="space-between" align="center">
        <Heading>Organization Defaults</Heading>
        <HStack spacing={2}>
          <Button
            variant="tertiary"
            onClick={downloadDefaultOrganizationTemplate}
            isLoading={downloading}
            leftIcon={<Icon as={ArrowDownTrayIcon} w={4} h={4} />}
          >
            Download Current Template
          </Button>
          <UploadFileButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!fileUploadRef || !fileUploadRef.current) return;
              fileUploadRef.current.click();
            }}
          />
          <a
            href={downloadUrl}
            hidden
            ref={fileDownloadRef}
            download="org_template.json"
          />
        </HStack>
      </Flex>
      {/* Demo Models Section */}
      <Box>
        <Heading size="lg" mb={6}>
          Demo Models
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {defaults?.initial_demo_models.map((model, index) => (
            <Card key={index} bg={cardBg} shadow="md">
              <CardHeader>
                <Heading size="md">{model.name}</Heading>
                <Badge colorScheme="blue" mt={2}>
                  {model.business_area}
                </Badge>
              </CardHeader>
              <CardBody>
                <Text>{model.description}</Text>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>

      <Divider />

      {/* Templates Section */}
      <Box>
        <Heading size="lg" mb={6}>
          Demo Templates
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {defaults?.initial_templates.model_documentation.map(
            (template, index) => (
              <Card key={index} bg={cardBg} shadow="md">
                <CardHeader>
                  <Heading size="md">{template.name}</Heading>
                </CardHeader>
                <CardBody>
                  <Text>{template.description}</Text>
                </CardBody>
              </Card>
            ),
          )}
        </SimpleGrid>
      </Box>
    </VStack>
  );
}

export default OrganizationDefaults;
