import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Button } from '@chakra-ui/react';
import { Select, MultiValue } from 'chakra-react-select';
import { DemoModel, Template } from '../../api/AdminAPI';

type OptionType = {
  value: string;
  label: string;
};

interface FormSelectProps {
  label: string;
  selectedItems: any;
  options: OptionType[];
  onChange: (newValue: string[]) => void;
  onSelectAll: () => void;
  allSelected: boolean;
  isTemplate: boolean;
  children?: React.ReactNode; // Accept children as a prop
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  selectedItems,
  options,
  onChange,
  onSelectAll,
  allSelected,
  isTemplate,
  children,
}) => {
  // prefill all templates
  useEffect(() => {
    if (isTemplate) {
      // Only pre-fill templates on initial load
      if (selectedItems.length === 0) {
        const defaultItems = options.map(
          (item) => item.value
        );
        onChange(defaultItems);
      }
    }
  }, [onChange]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (newValue: MultiValue<OptionType>) => {
    onChange(newValue.map((v) => v.value));
    if (options.length - newValue.length === 0) {
      setMenuIsOpen(false); // Close the menu if no options remain
    }
  };

  return (
    <FormControl mt={4}>
      <FormLabel>{label}</FormLabel>
      <Select<OptionType, true>
        isMulti
        value={isTemplate ?
          selectedItems.map((item: Template) => ({
            value: item.id,
            label: item.name,
          })) :
          selectedItems.map((item: DemoModel) => ({
            value: item.name,
            label: item.name,
          }))}
        onChange={handleChange}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        options={options}
        placeholder={`Select ${label.toLowerCase()}...`}
        closeMenuOnSelect={false}
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            background: 'white',
          }),
        }}
      />
      <Button size="sm" variant="link" mt={1} onClick={onSelectAll}>
        {allSelected ? 'Deselect all' : 'Select all'}
      </Button>
      {children && <div>{children}</div>}
    </FormControl>
  );
};

export default FormSelect;
