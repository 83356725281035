import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  PlusIcon,
  PuzzlePieceIcon,
  PencilIcon,
  TrashIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { DataTable } from '../../../components/DataTable';
import CustomFieldModal, {
  PutInventoryModelSchemaProps,
} from '../../../components/CustomFieldModal';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { EmptyStateDisplay } from '../../../components/EmptyStateDisplay';
import { ContentPageTitle } from '../../../components/Layout';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import ExpandableTextDisplay from '../../../components/ExpandableTextDisplay';
import {
  schemaTypesConfig,
  TypeConfig,
} from '../../../components/CustomFieldModal/schema_types';
import SidebarContext from '../../../contexts/SidebarContext';
import { UsersContext } from '../../../contexts';
import useModelSchema from '../../../hooks/useModelSchema';
import axios from 'axios';
import { DangerMenuItem } from '../../../components/DangerMenuItem';
import { useMutation } from 'react-query';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import API from '../../../api/API';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { CONFIG } from '../../../config';

const CustomFields = React.memo(() => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { userHasPermission, refetchOrganization } = useContext(UsersContext);
  const { setInSettings } = useContext(SidebarContext);
  const navigate = useNavigate();
  const toast = useToast();

  const canReadInventorySchema = userHasPermission(
    ['read_inventory_schema'],
    'all',
  );
  const canUpdateInventorySchema = userHasPermission(
    ['update_inventory_schema'],
    'all',
  );

  const {
    data: modelSchema,
    propertyItems: properties,
    isLoading,
    refetch,
  } = useModelSchema();

  useEffect(() => {
    if (!canReadInventorySchema) {
      navigate('/settings');
      return;
    }

    refetch();
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, [canReadInventorySchema]);

  // State to track which key is open in the edit modal
  const [editKeyModalOpen, setEditKeyModalOpen] = useState<string | null>(null);
  // State to track which key is open in the delete modal
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState<string | null>(
    null,
  );

  const deleteSchema = useMutation(
    ['organizations', 'custom_fields'],
    async ({ schema, settings, properties }: PutInventoryModelSchemaProps) => {
      return await API.PutInventoryModelSchema(
        schema,
        settings,
        'remove',
        properties,
      );
    },
    {
      onSuccess: (data, { operation, properties }) => {
        let title = `Model inventory field ${properties.schema.title} removed successfully`;
        toast({
          title,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onSave();
        refetchOrganization(); // to update the custom fields on the inventory model when used via user context.
      },
      onError: (error, variables, context) => {
        let errorMessage =
          'An error occurred while deleting the Model Inventory Field';

        if (axios.isAxiosError(error) && error.response) {
          errorMessage = error.response.data?.message || errorMessage;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast({
          title: 'Error deleting Model Inventory Field',
          description: errorMessage,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      },
    },
  );

  const onSave = () => {
    refetch();
    setEditKeyModalOpen(null); // Close modal after saving
    setDeleteKeyModalOpen(null);
  };

  const onDeleteClicked = (key: string) => {
    setEditKeyModalOpen(null); // Close edit modal first
    setDeleteKeyModalOpen(key);
    setConfirmDelete(true);
  };

  const onDeleteConfirmedClicked = (confirmed: boolean) => {
    setConfirmDelete(false);
    setEditKeyModalOpen(null); // Ensure edit modal is closed

    if (!confirmed) {
      setDeleteKeyModalOpen(null); // Clean up delete state if cancelled
      return;
    }

    const deletedSchema = modelSchema!.schema.properties![
      deleteKeyModalOpen!
    ] as JSONSchema7;
    const deletedSettings =
      modelSchema!.settings.properties![deleteKeyModalOpen!];

    const schemaCopy = _.cloneDeep(modelSchema!);
    delete schemaCopy.schema.properties![deleteKeyModalOpen!];
    delete schemaCopy.settings.properties![deleteKeyModalOpen!];

    deleteSchema.mutate({
      schema: schemaCopy.schema,
      settings: schemaCopy.settings,
      properties: {
        key: deleteKeyModalOpen!,
        schema: deletedSchema,
        settings: deletedSettings,
      },
      operation: 'remove',
    });
  };

  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }: any) => {
        return <Icon boxSize={6} color={'neutral.400'} as={PuzzlePieceIcon} />;
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row }: any) => (
        <Text fontWeight={'bold'} fontSize={'sm'}>
          {row.values.title}
        </Text>
      ),
    },
    {
      Header: 'Key',
      accessor: 'key',
      Cell: ({ row }: any) => (
        <Text fontSize={'sm'}>
          <pre>{row.values.key}</pre>
        </Text>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row }: any) => (
        <Text fontSize={'sm'}>
          {
            schemaTypesConfig.find(
              (t: TypeConfig) => t.typeId === row.original.typeId,
            )?.name
          }
        </Text>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ row }: any) => (
        <ExpandableTextDisplay
          value={row.original.description}
          maxLines={2}
          fontSize={'sm'}
        />
      ),
    },
    {
      Header: 'Required',
      accessor: 'requiredOnRegistration',
      Cell: ({ row }: any) => (
        <Text fontSize={'sm'}>
          {row.values.requiredOnRegistration ? 'Yes' : ''}
        </Text>
      ),
    },
  ];

  if (canUpdateInventorySchema) {
    tableColumns.push({
      Header: 'Actions',
      Cell: ({ row }: any) => (
        <Box className="action-button">
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              aria-label="Options"
              icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
              onClick={event => {
                event.stopPropagation();
              }}
            />
            <MenuList>
              <MenuItem
                icon={<Icon as={PencilIcon} boxSize={5} />}
                onClick={() => setEditKeyModalOpen(row.original.key)}
              >
                Edit Details
              </MenuItem>
              <MenuDivider />
              <DangerMenuItem
                icon={<Icon as={TrashIcon} boxSize={5} />}
                onClick={() => onDeleteClicked(row.original.key)}
              >
                Delete Field
              </DangerMenuItem>
            </MenuList>
          </Menu>
        </Box>
      ),
    });
  }

  return (
    <LoadingContainer isLoading={isLoading}>
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={8}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        data-testid="groups-settings"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <Box>
          <Breadcrumbs />
          <ContentPageTitle>
            Model Inventory Fields
            <MoreInfoPopOver
              title="Manage model inventory fields"
              link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/manage-inventory-custom-fields.html`}
              placement="right-end"
              iconProps={{
                ml: 2,
              }}
            />
          </ContentPageTitle>
        </Box>
        <Text fontSize="md">
          These fields will be added to every model in the inventory.
        </Text>
        <VStack w={'full'} alignItems={'flex-end'}>
          {canUpdateInventorySchema && (
            <Button
              onClick={() => setEditKeyModalOpen('add-new-field')} // Open modal for new field
              variant={'ghost'}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            >
              Add New Field
            </Button>
          )}
          {properties.length > 0 && (
            <DataTable
              data={properties}
              columns={tableColumns}
              enableSort={true}
              isInteractive
              onClickRow={(row: any) => {
                // Don't open edit modal if we're in delete mode
                if (!deleteKeyModalOpen && !confirmDelete) {
                  setEditKeyModalOpen(row.original.key);
                }
              }}
            />
          )}
        </VStack>
        {!isLoading && properties.length === 0 && (
          <Center w={'full'}>
            <EmptyStateDisplay variant="no-workflow">
              <Heading as="h5">
                No model inventory fields have been defined
              </Heading>
              <Text align="center" pb={5}>
                All inventory models are using the default fields.
              </Text>

              {canUpdateInventorySchema && (
                <Button
                  onClick={() => setEditKeyModalOpen('add-new-field')} // Open modal for new field
                  variant={'ghost'}
                  leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                >
                  Add New Field
                </Button>
              )}
            </EmptyStateDisplay>
          </Center>
        )}
      </VStack>

      {/* Single instance of CustomFieldModal */}
      {editKeyModalOpen && !confirmDelete && (
        <CustomFieldModal
          schema={modelSchema!}
          onSave={onSave}
          editKey={
            editKeyModalOpen === 'add-new-field' ? undefined : editKeyModalOpen
          } // Pass undefined for new field
          isOpen={!!editKeyModalOpen}
          onCustomFieldModalClose={() => setEditKeyModalOpen(null)} // Close modal
        />
      )}

      {deleteKeyModalOpen && confirmDelete && (
        <ConfirmationAlert
          title={`Deleting field: ${deleteKeyModalOpen}`}
          dialogBody={'Are you sure?'}
          open={confirmDelete}
          onConfirm={onDeleteConfirmedClicked}
        />
      )}
    </LoadingContainer>
  );
});

export default CustomFields;
