import { SectionContentsProps } from '../../Layout/DocumentationPage';
import ContentContainer from '../../Layout/ContentContainer';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ContentPageH2 } from '../../Layout';
import _ from 'lodash';
import useTestResult from '../../../hooks/useTestResult';
import { LoadingContainer } from '../../LoadingContainer';
import { useFigures } from '../../../hooks/useFigure';
import FigureDisplay from '../../FigureDisplay';
import { MetadataContentEditor } from './MetadataContentEditor';
import { keyable } from '../../../models/utils';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Code,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ResultSummary } from './ResultSummary';
import { EmptyStateDisplay } from '../../EmptyStateDisplay';

import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import ConfirmationAlert from '../../ConfirmationAlert';
import InputInfoModal from '../../InputInfoModal';
import API from '../../../api/API';
import { getModelDocumentType } from '../../../models/model_document';
import InventoryModelContext from '../../../contexts/InventoryModel';
import BlockWrapper, { BlockWrapperContext } from '../BlockWrapper';
import { CONFIG } from '../../../config';

export const TEST_DESCRIPTION_CONTENT_ID_PREFIX = 'test_description';

export const FailedBadge = ({ size = 'sm' }: { size?: string }) => (
  <Tag size={size} colorScheme="yellow">
    <TagLeftIcon as={ExclamationTriangleIcon} />
    <TagLabel>Requires Attention</TagLabel>
  </Tag>
);

export const PassedBadge = ({ size = 'sm' }: { size?: string }) => (
  <Tag size={size} colorScheme="green">
    <TagLeftIcon as={CheckCircleIcon} />
    <TagLabel>Satisfactory</TagLabel>
  </Tag>
);
export const formatTestTitleFromID = (testTitle: string) => {
  return `${_.startCase(testTitle.split('.').pop() || '')}`;
};
export const TestParamsRenderer = ({ params }: keyable) => (
  <Box px={3}>
    <Alert
      status="info"
      flexDirection="column"
      alignItems={'flex-start'}
      mb={8}
      borderRadius={'md'}
      variant={'subtle'}
    >
      <HStack alignItems={'flex-start'} w={'full'}>
        <AlertIcon />
        <VStack alignItems={'flex-start'} w={'full'} marginInlineStart={0}>
          <AlertTitle>Test Parameters</AlertTitle>

          <AlertDescription w={'full'}>
            <TableContainer>
              <Table
                size="sm"
                variant="simple"
                rounded={'md'}
                overflow={'hidden'}
                border={'1px solid'}
                borderColor={useColorModeValue('neutral.200', 'neutral.700')}
              >
                <Thead
                  bg={useColorModeValue('neutral.200', 'neutral.700')}
                  color={useColorModeValue('neutral.700', 'neutral.100')}
                >
                  <Tr>
                    <Th>NAME</Th>
                    <Th isNumeric>VALUE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(params)
                    .filter(([key, value]) => typeof value !== 'object')
                    .map(([key, value]) => (
                      <Tr key={`param-${key}`}>
                        <Td>
                          <Code bg={'transparent'} fontSize={'md'}>
                            {key}
                          </Code>
                        </Td>
                        <Td isNumeric fontSize={'md'}>
                          {value as string}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AlertDescription>
        </VStack>
      </HStack>
    </Alert>
  </Box>
);

const ContentRenderer = ({
  hideHeader = false,
  contents,
  documentType,
  readOnly,
  overrideDocumentType,
  isInView,
}: SectionContentsProps & {
  isInView: boolean;
  hideHeader?: boolean;
}) => {
  const { content_id, options = {} } = contents;
  const { title } = options;
  const { config, enableConfig } = useContext(BlockWrapperContext);
  const { testResult, isLoading, isError, error } = useTestResult(
    content_id,
    documentType ? getModelDocumentType(documentType) : undefined,
    isInView,
  );

  const { figures = [], isLoading: isLoadingFigures } = useFigures({
    _ref_id: testResult?.ref_id,
  });
  const { inventoryModel } = useContext(InventoryModelContext);

  const testDescriptionContentId = `${TEST_DESCRIPTION_CONTENT_ID_PREFIX}:${testResult?.test_name}`;
  const metadataContents = {
    content_id: testDescriptionContentId,
    content_type: 'text',
  };

  let testTitle = testResult?.title || title || testResult?.test_name || '';
  // Grab the last part of the test ID since it's a namespaced ID, e.g. validmind.data_validation.TestNmae
  testTitle = testTitle.split('.').pop();

  // This will enable the configuration options for the block
  useEffect(() => {
    enableConfig({
      title: testResult?.title || title || testResult?.test_name,
      text: true,
      params: testResult?.params !== null,
      tables: (testResult?.summary && testResult.summary.length > 0) || false,
      figures: figures.length > 0,
    });
  }, [testResult]);

  const getTestResultHistoryForPage = useCallback(
    async (
      page: number,
      limit: number,
      params?: Record<string, any>,
      sortBy?: string,
      sortOrder?: string,
    ) => {
      if (inventoryModel) {
        const resp = await API.GetTestResultHistoryForInventoryModel(
          inventoryModel.cuid,
          content_id,
          documentType ? getModelDocumentType(documentType) : undefined,
          page,
          limit,
          params,
          sortBy,
          sortOrder,
        );
        return {
          history: resp.results,
          total: resp.total,
        };
      }
      return {
        history: [],
        total: 0,
      };
    },
    [inventoryModel, content_id, documentType],
  );

  // A 404 error from the API means the library has not sent any data
  // for this content block so we show an empty state
  if (isError || !testResult) {
    return (
      <>
        <ContentPageH2>
          {<Text>{_.startCase(content_id.split('.').pop())}</Text>}
        </ContentPageH2>
        <EmptyStateDisplay variant="no-activity">
          <Heading as={'h5'}>
            The data associated with this Test-Driven Block (
            <code>{content_id}</code>) is missing.
          </Heading>
          <Text align={'center'}>
            To populate this content block please make sure to run the
            associated documentation test suite.
          </Text>
          <Text align={'center'} pb={10}>
            Please refer to{' '}
            <a href={CONFIG.VALIDMIND_DOCS_URL} target="_blank">
              ValidMind's documentation
            </a>{' '}
            for more information.
          </Text>
        </EmptyStateDisplay>
      </>
    );
  }
  if (testResult) {
    return (
      <LoadingContainer isLoading={isLoading || isLoadingFigures}>
        {!hideHeader && !config?.hideTitle && (
          <Heading as={'h2'} ml={3}>
            {testResult && (
              <HStack justifyContent={'space-between'}>
                <Text>
                  {formatTestTitleFromID(
                    testResult?.title || title || testResult?.test_name || '',
                  )}
                </Text>
                {testResult?.passed === null ? (
                  ''
                ) : testResult?.passed ? (
                  <PassedBadge size="lg" />
                ) : (
                  <FailedBadge size="lg" />
                )}
              </HStack>
            )}
          </Heading>
        )}
        <Box mt={3}>
          <InputInfoModal
            hideLabel={config?.hideTitle}
            createdAt={testResult.created_at}
            inputs={testResult.inputs}
            user={testResult.user}
            contentType={testResult.content_type}
            getHistoryForPage={getTestResultHistoryForPage}
            contentID={content_id}
            title={formatTestTitleFromID(
              testResult?.title || title || testResult?.test_name || '',
            )}
          />
        </Box>
        <ContentContainer>
          {!config?.hideText && (
            <MetadataContentEditor
              readOnly={readOnly}
              key={testDescriptionContentId}
              contents={metadataContents}
              overrideDocumentType={overrideDocumentType}
              hideToolbar
            />
          )}
          {testResult?.params !== null &&
            testResult?.params !== undefined &&
            !config?.hideParams && (
              <TestParamsRenderer params={testResult.params} />
            )}
        </ContentContainer>
        {testResult && testResult.summary && !config?.hideTables && (
          <ResultSummary summary={testResult.summary} />
        )}
        {!config?.hideFigures &&
          figures.map((figure: any) => <FigureDisplay figure={figure} />)}
      </LoadingContainer>
    );
  }
  return null;
};

export function TestResultContent({
  contents,
  removeBlock,
  readOnly,
  hideHeader = false,
  overrideDocumentType,
  documentType,
}: SectionContentsProps) {
  const { content_id, content_type, options = {} } = contents;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isInView, setIsInView] = useState(false);

  if (!content_id) {
    return <ContentContainer>Undefined content_id</ContentContainer>;
  }

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && removeBlock) {
      removeBlock(contents);
    }
    setConfirmDelete(false);
  };

  return (
    <>
      <ConfirmationAlert
        open={confirmDelete}
        title={`Remove Test block`}
        dialogBody={`Are you sure you'd like to remove this test block?`}
        onConfirm={onDeleteConfirmed}
      />
      <BlockWrapper
        documentType={documentType!}
        contentId={content_id}
        contentType={content_type}
        readOnly={readOnly}
        showDeleteConfirmation={() => setConfirmDelete(true)}
        setIsInView={setIsInView}
      >
        <ContentRenderer
          hideHeader={hideHeader}
          isInView={isInView}
          contents={contents}
          documentType={documentType}
          readOnly={readOnly}
          overrideDocumentType={overrideDocumentType}
        />
      </BlockWrapper>
    </>
  );
}
