import {
    Box,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Card,
    CardBody,
    Switch,
    Flex,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import AdminAPI from '../../api/AdminAPI';
import AdminUsers from './AdminUsers';

const Settings = () => {
    const [allowUserOnboarding, setAllowUserOnboarding] = useState(false);
    const [syncOidcRoles, setSyncOidcRoles] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    // Load initial settings
    useEffect(() => {
        const loadSettings = async () => {
            try {
                setIsLoading(true);
                const response = await AdminAPI.GetGeneralSettings();
                setAllowUserOnboarding(response.allow_user_onboarding);
                setSyncOidcRoles(response.sync_oidc_roles);
            } catch (error) {
                toast({
                    title: 'Error loading settings',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        loadSettings();
    }, [toast]);

    const handleSettingChange = async (setting: string, value: boolean) => {
        try {
            setIsLoading(true);
            const response = await AdminAPI.UpdateGeneralSettings({
                [setting]: value,
            });

            // Update local state with the response from the server
            setAllowUserOnboarding(response.allow_user_onboarding);
            setSyncOidcRoles(response.sync_oidc_roles);

            toast({
                title: 'Settings updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error updating settings',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            // Revert the toggle if the API call fails
            if (setting === 'allow_user_onboarding') {
                setAllowUserOnboarding(!value);
            } else if (setting === 'sync_oidc_roles') {
                setSyncOidcRoles(!value);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <Heading mb={6}>Settings</Heading>

            <Tabs colorScheme="brand">
                <TabList borderBottomWidth="1px" mb={6}>
                    <Tab>General</Tab>
                    <Tab>Admin Users</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel p={0}>
                        <Flex direction="column" gap={6}>
                            <Card variant="outline">
                                <CardBody>
                                    <Box mb={4}>
                                        <Heading size="md">Onboarding</Heading>
                                    </Box>
                                    <Flex align="center" gap={3}>
                                        <Switch
                                            id="user-onboarding"
                                            colorScheme="brand"
                                            size="md"
                                            isChecked={allowUserOnboarding}
                                            isDisabled={isLoading}
                                            onChange={e =>
                                                handleSettingChange(
                                                    'allow_user_onboarding',
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                        <Box>
                                            <Box fontSize="md" fontWeight="medium">
                                                Enable user self-onboarding
                                            </Box>
                                            <Box fontSize="md" color="gray.500">
                                                When enabled, users can self-register and create their own
                                                organization.
                                            </Box>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>

                            <Card variant="outline">
                                <CardBody>
                                    <Box mb={4}>
                                        <Heading size="md">OIDC</Heading>
                                    </Box>
                                    <Flex align="center" gap={3}>
                                        <Switch
                                            id="sync-oidc-roles"
                                            colorScheme="brand"
                                            size="md"
                                            isChecked={syncOidcRoles}
                                            isDisabled={isLoading}
                                            onChange={e =>
                                                handleSettingChange(
                                                    'sync_oidc_roles',
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                        <Box>
                                            <Box fontSize="md" fontWeight="medium">
                                                Sync OIDC Roles
                                            </Box>
                                            <Box fontSize="md" color="gray.500">
                                                When enabled, roles in OIDC claims will be automatically synced with VM roles.
                                            </Box>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </Flex>
                    </TabPanel>
                    <TabPanel><AdminUsers /></TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default Settings;
