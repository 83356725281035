import { useMutation } from 'react-query';
import API from '../../api/API';
import { TUser } from '../../models';
import { Onboarding } from '../../models/user';
import { useContext, useMemo } from 'react';
import UsersContext from '../../contexts/UsersContext';
import _ from 'lodash';

const defaultOnboardingSettings: Onboarding = {
  isDismissed: false,
  getAccount: true,
  registerModel: false,
  startDocumentation: false,
  collaborateDocumentation: false,
  submitDocumentation: false,
  findResources: false,
};

export function useUserUISettings() {
  const { currentUser: user, setCurrentUser } = useContext(UsersContext);

  const patchUISettings = useMutation(
    [],
    async ({ user }: { user: TUser }) => {
      // Initialize empty ui_settings if undefined
      const settings = user.ui_settings || {
        onboarding: {},
        colorMode: 'light',
      };
      return await API.PatchCurrentUserUISettings(settings);
    },
    {},
  );

  const updateOnboarding = (updates: Partial<Onboarding>) => {
    if (!user) return;
    let onboarding: Onboarding = user.ui_settings?.onboarding || {};
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user.ui_settings,
        onboarding: { ...onboarding, ...updates },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const updateColorMode = (colorMode: string = 'light') => {
    if (!user) return;
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user.ui_settings,
        colorMode,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const resetOnboarding = () => {
    updateOnboarding(defaultOnboardingSettings);
  };

  const getColorModeSetting = (): string => {
    return user?.ui_settings?.colorMode || 'light';
  };

  const getOnboardingSetting = (name: keyof Onboarding): boolean => {
    return user?.ui_settings?.onboarding?.[name] ?? false;
  };

  const setOnboardingSetting = (
    name: keyof Onboarding,
    value: boolean,
  ): void => {
    if (!user) return;
    let onboarding: Onboarding = user.ui_settings?.onboarding || {};
    onboarding[name] = value;
    const updatedUser = {
      ...user,
      ui_settings: { ...user.ui_settings, onboarding },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const isCompleted = useMemo(() => {
    return _.every(
      _.omit(user?.ui_settings?.onboarding, 'isDismissed'),
      value => value === true,
    );
  }, [user?.ui_settings?.onboarding]);

  const getModelInventoryColumns = (): string[] | undefined => {
    return user?.ui_settings?.modelInventoryColumns?.columns;
  };

  const updateModelInventoryColumns = (modelInventoryColumns: string[]) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        modelInventoryColumns: {
          version: '1.0',
          columns: modelInventoryColumns,
        },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getModelFindingsColumns = (): string[] | undefined => {
    return user?.ui_settings?.modelFindingsColumns?.columns;
  };

  const updateModelFindingsColumns = (modelFindingsColumns: string[]) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        modelFindingsColumns: {
          version: '1.0',
          columns: modelFindingsColumns,
        },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getDisplayTableModel = (): boolean => {
    if (user?.ui_settings?.displayTableModel !== undefined) {
      return user?.ui_settings?.displayTableModel!;
    }
    return true;
  };

  const updateDisplayTableModel = (displayTableModel: boolean) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        displayTableModel,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getHideVMInsightsSidebar = () => {
    if (user?.ui_settings?.hideVMInsightsSidebar !== undefined) {
      return user?.ui_settings?.hideVMInsightsSidebar!;
    }
    return true;
  };

  const updateHideVMInsightsSidebar = (hideVMInsightsSidebar: boolean) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        hideVMInsightsSidebar,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  return {
    user,
    updateOnboarding,
    resetOnboarding,
    getOnboardingSetting,
    setOnboardingSetting,
    getColorModeSetting,
    patchUISettings,
    defaultOnboardingSettings,
    isCompleted,
    updateColorMode,
    getModelInventoryColumns,
    updateModelInventoryColumns,
    getModelFindingsColumns,
    updateModelFindingsColumns,
    getDisplayTableModel,
    updateDisplayTableModel,
    getHideVMInsightsSidebar,
    updateHideVMInsightsSidebar,
  };
}
