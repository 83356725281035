import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Container,
    Heading,
    useToast,
    Text,
    Alert,
    AlertIcon,
    FormErrorMessage,
    VStack,
    InputGroup,
    InputRightElement,
    Icon,
} from '@chakra-ui/react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import AdminAPI from '../../api/AdminAPI';
import { useFlags, AuthConfig } from '../../hooks/useFlags';
import { isValidEmail, generateStrongPassword } from '../../utils';
import ValidMindLogo from '../../components/ValidMindLogo';

interface BootstrapFormData {
    organizationName: string;
    adminEmail: string;
    adminFirstName: string;
    adminLastName: string;
    adminPassword: string;
}

interface BootstrapFormProps {
    onSuccess: () => Promise<void>;
}

const BootstrapForm = ({ onSuccess }: BootstrapFormProps) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { authConfig = {
        auth_create_user_enabled: false,
        auth_provider: "okta"
    } } = useFlags() as { authConfig: AuthConfig };
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState<BootstrapFormData>({
        organizationName: '',
        adminEmail: '',
        adminFirstName: '',
        adminLastName: '',
        adminPassword: generateStrongPassword(),
    });

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setFormData(prev => ({ ...prev, adminEmail: email }));

        if (!email) {
            setEmailError('Email is required');
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const isFormValid = () => {
        return (
            formData.organizationName.trim() &&
            formData.adminFirstName.trim() &&
            formData.adminLastName.trim() &&
            formData.adminEmail.trim() &&
            isValidEmail(formData.adminEmail) &&
            (!authConfig.auth_create_user_enabled || formData.adminPassword.trim())
        );
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setError(null);
            await AdminAPI.CreateBootstrapOrganization({
                organization_name: formData.organizationName,
                admin_user: {
                    email: formData.adminEmail,
                    first_name: `${formData.adminFirstName}`,
                    last_name: `${formData.adminLastName}`,
                    ...(authConfig.auth_create_user_enabled && { password: formData.adminPassword }),
                },
            });

            toast({
                title: 'Organization created',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            await onSuccess();
        } catch (error: any) {
            setError(error.message || 'Failed to create organization');
            toast({
                title: 'Error',
                description: error.message || 'Failed to create organization',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordGenerate = () => {
        setFormData(prev => ({
            ...prev,
            adminPassword: generateStrongPassword(),
        }));
    };

    return (
        <Box p={8}>
            <Container maxW="630px">
                <VStack spacing={8} align="center">
                    <ValidMindLogo />
                    <Heading>Initial Setup</Heading>
                    <Text mb={4} color="gray.600">
                        Create your first organization and admin user to get started.
                    </Text>

                    <Stack spacing={4} as="form" onSubmit={handleSubmit} width="100%">
                        {error && (
                            <Alert status="error">
                                <AlertIcon />
                                {error}
                            </Alert>
                        )}

                        <FormControl isRequired>
                            <FormLabel>Organization Name</FormLabel>
                            <Input
                                name="organizationName"
                                value={formData.organizationName}
                                onChange={handleInputChange}
                                placeholder="Enter organization name"
                                bg="white"
                            />
                        </FormControl>

                        <Box borderWidth="1px" borderRadius="lg" p={4}>
                            <Text fontWeight="bold" mb={2}>
                                Admin User Details
                            </Text>
                            <Stack spacing={4}>
                                <FormControl isRequired isInvalid={!!emailError}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        name="adminEmail"
                                        type="email"
                                        value={formData.adminEmail}
                                        onChange={handleEmailChange}
                                        placeholder="Enter admin email"
                                        bg="white"
                                    />
                                    {emailError && (
                                        <FormErrorMessage>{emailError}</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        name="adminFirstName"
                                        value={formData.adminFirstName}
                                        onChange={handleInputChange}
                                        placeholder="Enter admin first name"
                                        bg="white"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        name="adminLastName"
                                        value={formData.adminLastName}
                                        onChange={handleInputChange}
                                        placeholder="Enter admin last name"
                                        bg="white"
                                    />
                                </FormControl>

                                {authConfig.auth_create_user_enabled && (
                                    <FormControl isRequired>
                                        <FormLabel>Password</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type={showPassword ? 'text' : 'password'}
                                                name="adminPassword"
                                                value={formData.adminPassword}
                                                onChange={handleInputChange}
                                                placeholder="Enter admin password"
                                                bg="white"
                                            />
                                            <InputRightElement width="4.5rem">
                                                <Button
                                                    h="1.75rem"
                                                    size="sm"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    <Icon
                                                        as={showPassword ? EyeSlashIcon : EyeIcon}
                                                        w={4}
                                                        h={4}
                                                    />
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            mt={1}
                                            onClick={handlePasswordGenerate}
                                        >
                                            Generate new password
                                        </Button>
                                    </FormControl>
                                )}

                            </Stack>
                        </Box>

                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={isLoading}
                            isDisabled={!isFormValid()}
                            mt={4}
                        >
                            Create Organization
                        </Button>
                    </Stack>
                </VStack>
            </Container>
        </Box>
    );
};

export default BootstrapForm;
