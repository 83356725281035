import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import API, { TTest } from '../../api/API';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spacer,
  Tag,
  TagLabel,
  Checkbox,
  useColorModeValue,
  HStack,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  ListItem,
  UnorderedList,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  CloseButton,
  Badge,
  Button,
} from '@chakra-ui/react';

import { ModelDocumentTypeEnum } from '../../models/model_document';
import { DataTable } from '../DataTable';
import AvatarProxy from '../AvatarProxy';
import { displayFormatedDateAndTime } from '../../utils';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { TestResultContent } from '../Templates/ModelDocumentation/TestResultContent';
import { LoadingContainer } from '../LoadingContainer';
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import MoreInfoPopOver from '../MoreInfoPopOver';
import InventoryModelContext from '../../contexts/InventoryModel';
import { CONFIG } from '../../config';

interface AddBlockTestDrivenModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (content_type: string, content_id: string) => Promise<void>;
  documentType: ModelDocumentTypeEnum;
}

const CustomTypeHeader = () => {
  return (
    <HStack>
      <Text>TYPE</Text>
      <Popover placement="bottom" closeOnBlur trigger="hover">
        <PopoverTrigger>
          <Icon as={InformationCircleIcon} boxSize={5} color={'neutral.400'} />
        </PopoverTrigger>
        <PopoverContent rounded={'md'}>
          <PopoverHeader rounded={'md'}>TEST TYPES</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody
            textTransform={'none'}
            fontWeight={'normal'}
            fontSize={'sm'}
            overflow={'scroll'}
            w={'full'}
            whiteSpace={'normal'}
            rounded={'md'}
          >
            <UnorderedList>
              <ListItem>
                <strong>Default:</strong> A function contained in the ValidMind
                Library, designed to run a specific quantitative test on the
                dataset or model. Tests are the building blocks of ValidMind,
                used to evaluate and document models and datasets, and can be
                run individually or as part of a suite defined by your model
                documentation template.
              </ListItem>
              <ListItem>
                <strong>Custom:</strong> Custom tests are functions that you
                define to evaluate your model or dataset. These functions can be
                registered with ValidMind to be used in the platform.
              </ListItem>
            </UnorderedList>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default function AddBlockTestDrivenModal({
  isOpen,
  onClose,
  onAdd,
  documentType,
}: AddBlockTestDrivenModalProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const [selectedTests, setSelectedTests] = useState<TTest[]>([]);
  const [previewTest, setPreviewTest] = useState<TTest | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading } = useQuery(
    ['inventory-model', inventoryModel?.cuid, 'tests'],
    async () => {
      const testResultsKeys = await API.GetTests(inventoryModel!, documentType);
      return testResultsKeys.sort((a, b) => a.name.localeCompare(b.name));
    },
  );

  const onInsert = async () => {
    setIsSubmitting(true);
    try {
      for (const test of selectedTests) {
        await onAdd('test', test.key);
      }
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onModalClose = () => {
    onClose();
    setSelectedTests([]);
    setPreviewTest(undefined);
    setSearchQuery('');
  };

  const columns = useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: ({ row }: any) => {
          const foundIndex = selectedTests.findIndex(
            key => key === row.original,
          );
          const isChecked = foundIndex !== -1;
          return (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                isChecked={isChecked}
                size={'lg'}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedTests([...selectedTests, row.original]);
                  } else {
                    setSelectedTests(
                      selectedTests.filter(key => key !== row.original),
                    );
                  }
                }}
              />
              {foundIndex !== -1 && (
                <Badge fontFamily={'mono'} roundedLeft={0}>
                  {foundIndex + 1}
                </Badge>
              )}
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Box display="flex" flex={1} flexDirection="column">
              <Text fontWeight={'bold'}>{row.values.name}</Text>
              <Text
                textOverflow="ellipsis"
                color="neutral.500"
                overflow="hidden"
                fontSize={'sm'}
                maxW={'300px'}
              >
                {row.original.key}
              </Text>
            </Box>
          );
        },
      },
      {
        accessor: 'is_default',
        Header: <CustomTypeHeader />,
        Cell: ({ value }: any) => {
          return <Text fontSize={'sm'}>{value ? 'Default' : 'Custom'}</Text>;
        },
      },
      {
        accessor: 'tag',
        Header: 'Tag',
        Cell: ({ row }: any) => {
          return (
            <Text fontSize={'sm'} fontFamily={'monospace'}>
              {row.values.tag}
            </Text>
          );
        },
      },
      {
        accessor: 'models',
        Header: 'Models',
        Cell: ({ value }: any) => {
          return (
            <HStack>
              {value.map((m: string) => (
                <Tag>
                  <TagLabel fontFamily={'monospace'} fontSize={'sm'}>
                    {m}
                  </TagLabel>
                </Tag>
              ))}
            </HStack>
          );
        },
      },
      {
        accessor: 'datasets',
        Header: 'Datasets',
        Cell: ({ value }: any) => {
          return (
            <VStack alignItems={'flex-start'}>
              {value.map((ds: string) => (
                <Tag>
                  <TagLabel fontFamily={'monospace'} fontSize={'sm'}>
                    {ds}
                  </TagLabel>
                </Tag>
              ))}
            </VStack>
          );
        },
      },
      {
        accessor: 'created_at',
        Header: 'Last Updated',
        Cell: ({ value }: any) => {
          return (
            <Text fontSize={'sm'}>{displayFormatedDateAndTime(value)}</Text>
          );
        },
      },
      {
        accessor: 'user',
        Header: 'Updated By',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Box>
              <Tag key={value.cuid} size={'md'}>
                <AvatarProxy
                  src={value.picture}
                  size="xs"
                  name={value.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{value.name}</TagLabel>
              </Tag>
            </Box>
          );
        },
      },
    ],
    [selectedTests],
  );

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter((test: TTest) => {
      if (!searchQuery) {
        return true;
      }
      return test.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [data, searchQuery]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <ModalOverlay />
      <ModalContent
        h="95vh"
        maxHeight="95vh"
        width="100%"
        maxW="95%"
        bgColor="white"
      >
        <ModalHeader>
          Select a Test-Driven Block
          <MoreInfoPopOver
            title="Insert Test Results"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/developer/model-documentation/work-with-test-results.html`}
            placement="bottom"
            iconProps={{
              ml: 2,
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flex={1}>
          <LoadingContainer isLoading={isLoading}>
            <VStack flex={1} alignItems="flex-start">
              <InputGroup w={'50%'}>
                <InputLeftElement pointerEvents="none">
                  <Icon
                    as={MagnifyingGlassIcon}
                    boxSize={5}
                    color={'neutral.400'}
                  />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="Search by name"
                  maxW="300px"
                  onChange={e => {
                    setSearchQuery(e.target.value);
                  }}
                  value={searchQuery}
                  w={'full'}
                />
              </InputGroup>

              <Allotment vertical separator>
                <Allotment.Pane>
                  <Box w="full" h="full" overflow="scroll" mb={4}>
                    <DataTable
                      data={filteredData}
                      columns={columns}
                      isInteractive={true}
                      pageSize={99999}
                      onClickRow={(row: any) => {
                        setPreviewTest(row.original);
                      }}
                      getRowProps={(row: any) => {
                        const defaultBgColor =
                          row.index % 2 === 0
                            ? useColorModeValue(
                                'var(--chakra-colors-neutral-25)',
                                'var(--chakra-colors-neutral-1000)',
                              )
                            : 'transparent';
                        const highlightedBgColor = useColorModeValue(
                          'var(--chakra-colors-brand-25)',
                          'var(--chakra-colors-brand-800)',
                        );

                        return {
                          style: {
                            background:
                              previewTest?.key === row.original.key
                                ? highlightedBgColor
                                : defaultBgColor,
                            borderBottom: '1px solid',
                            color:
                              previewTest?.key === row.original.key
                                ? useColorModeValue(
                                    'var(--chakra-colors-brand-700)',
                                    'unset',
                                  )
                                : 'unset',
                            borderBottomColor: useColorModeValue(
                              'var(--chakra-colors-neutral-100)',
                              'var(--chakra-colors-neutral-850)',
                            ),
                          },
                        };
                      }}
                    />
                  </Box>
                </Allotment.Pane>
                {previewTest && (
                  <Allotment.Pane>
                    <VStack overflow="scroll" h={'full'} p={4}>
                      <Stack alignItems={'flex-end'} w={'full'}>
                        <CloseButton
                          onClick={() => setPreviewTest(undefined)}
                        />
                      </Stack>
                      <Box borderRadius={'md'} px={4}>
                        <TestResultContent
                          readOnly={true}
                          contents={{
                            content_id: previewTest.key,
                            content_type: documentType,
                          }}
                          documentType={documentType}
                        />
                      </Box>
                    </VStack>
                  </Allotment.Pane>
                )}
              </Allotment>
            </VStack>
          </LoadingContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onModalClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            hidden={selectedTests.length === 0}
            onClick={onInsert}
            isDisabled={selectedTests.length === 0 || isSubmitting}
            isLoading={isSubmitting}
            variant={'primary'}
          >
            {`Insert ${selectedTests.length} Test Result${
              selectedTests.length > 1 ? 's' : ''
            } to Document`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
