import { Route, Routes } from 'react-router-dom';
import AdminApp from './admin/AdminApp';
import NotFound from './pages/NotFound';
import { CONFIG } from './config';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import BootstrapCheck from './BootstrapCheck';

function App() {
  return (
    <Routes>
      <Route path="/admin/*" element={<AdminApp />} />
      <Route path="/*" element={<BootstrapCheck />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function withConditionalLDProvider(Component: React.ComponentType) {
  if (CONFIG.USE_LAUNCHDARKLY) {
    const LDApp = withLDProvider({
      clientSideID: CONFIG.REACT_APP_LD_CLIENT_ID,
      options: {
        bootstrap: 'localStorage',
      },
    })(Component);

    return function (props: React.ComponentProps<typeof Component>) {
      return <LDApp {...props} />;
    };
  }

  return function (props: React.ComponentProps<typeof Component>) {
    return <Component {...props} />;
  };
}

const VMApp = withConditionalLDProvider(App);
export default VMApp;
