import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Box,
  Button,
  Heading,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AssessmentOption, Guideline } from '../../../../models/guideline';
import useValidationGuideline from '../../../../hooks/useValidationGuideline';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../../../api/API';
import { LoadingContainer } from '../../../LoadingContainer';
import { Label } from '../../../Layout';
import { ChevronDownIcon, Icon } from '@chakra-ui/icons';
import RiskAssessmentNotes from './RiskAssessmentNotes';
import RiskAssessmentItemEvidenceItem from './RiskAssessmentItemEvidenceItem';
import RiskAssessmentAddEvidenceModal from './RiskAssessmentAddEvidenceModal';
import { ArrowUturnLeftIcon, LinkIcon } from '@heroicons/react/24/outline';
import RiskAssessmentAddFindingModal from './RiskAssessmentAddFindingModal';
import FindingRow from '../../../FindingsList/FindingRow';
import ConfirmationAlert from '../../../ConfirmationAlert';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import DocumentationContext from '../../../../contexts/DocumentationContext';
import { EmptyStateDisplay } from '../../../EmptyStateDisplay';
import MoreInfoPopOver from '../../../../components/MoreInfoPopOver';
import { TemplateSectionContents } from '../../../../models/template';
import EvidenceDocumentTree from './EvidenceDocumentTree';
import RiskAssessmentAddEvidenceModal2 from './RiskAssessmentAddEvidenceModal2';
import InventoryModelContext from '../../../../contexts/InventoryModel';
import { TInventoryModel } from '../../../../models/inventory_model';
import { CONFIG } from '../../../../config';

interface PostValidationGuidelineAssessmentVars {
  inventoryModel: TInventoryModel;
  content_id: string;
  assessmentOption: AssessmentOption | null;
}

interface PostEvidenceVars {
  inventoryModel: TInventoryModel;
  content_id: string;
  documentType: ModelDocumentTypeEnum;
  contents: TemplateSectionContents[];
}

interface DeleteEvidenceVars {
  inventoryModel: TInventoryModel;
  content_id: string;
  documentType: ModelDocumentTypeEnum;
  content: TemplateSectionContents;
}

interface PostFindingVars {
  inventoryModel: TInventoryModel;
  content_id: string;
  findingCuids: string[];
}

interface GuidelineItemProps {
  content_id: string;
  readOnlyFields: boolean;
}

export default function GuidelineItem({
  content_id,
  readOnlyFields,
}: GuidelineItemProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { inventoryModel } = useContext(InventoryModelContext);
  const { hasValidationReportResults } = useContext(DocumentationContext);

  const [guideline, setGuideline] = useState<Guideline>();
  const [assessmentOption, setAssessmentOption] = useState<AssessmentOption>();
  const [showAddEvidenceModal, setShowAddEvidenceModal] = useState(false);
  const [showAddEvidenceModal2, setShowAddEvidenceModal2] = useState(false);
  const [showAddFindingModal, setShowAddFindingModal] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);

  const { data, isLoading, refetch } = useValidationGuideline({
    inventoryModel: inventoryModel!,
    content_id,
  });

  useEffect(() => {
    setGuideline(data);
  }, [data]);

  useEffect(() => {
    if (guideline && guideline.assessment) {
      setAssessmentOption(guideline.assessment.option);
    }
  }, [guideline]);

  const { developerEvidences, validatorEvidences } = useMemo(() => {
    const developerEvidences =
      guideline?.assessment?.evidences?.filter(e => {
        return (
          e.evidence_document_type === ModelDocumentTypeEnum.model_documentation
        );
      }) || [];

    const validatorEvidences =
      guideline?.assessment?.evidences?.filter(e => {
        return (
          e.evidence_document_type === ModelDocumentTypeEnum.validation_report
        );
      }) || [];

    return {
      developerEvidences,
      validatorEvidences,
    };
  }, [guideline?.assessment?.evidences]);

  const postAssessment = useMutation(
    [],
    async ({
      inventoryModel,
      content_id,
      assessmentOption,
    }: PostValidationGuidelineAssessmentVars) => {
      return await API.PostValidationGuidelineAssessment(
        inventoryModel,
        content_id,
        assessmentOption ? assessmentOption.cuid : '',
      );
    },
    {
      onSuccess: assessment => {
        setGuideline({ ...guideline!, assessment: assessment });
        toast({
          title: 'Assessment saved.',
          description: 'Your assessment has been saved.',
          status: 'success',
          duration: 3000,
          variant: 'subtle',
          isClosable: true,
          position: 'bottom-right',
        });
        queryClient.invalidateQueries({
          queryKey: [
            'inventory-model',
            inventoryModel?.cuid!,
            'risk-assessment-summary',
          ],
        });
      },
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          variant: 'subtle',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const postEvidences = useMutation(
    [],
    async ({
      inventoryModel,
      content_id,
      documentType,
      contents,
    }: PostEvidenceVars) => {
      return await API.PatchValidationGuidelineAssessmentEvidences(
        inventoryModel,
        content_id,
        documentType,
        contents,
      );
    },
    {
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        refetch();
      },
    },
  );

  const deleteEvidence = useMutation(
    [],
    async ({
      inventoryModel,
      content_id,
      documentType,
      content,
    }: DeleteEvidenceVars) => {
      return await API.DeleteValidationGuidelineAssessmentEvidences(
        inventoryModel,
        content_id,
        documentType,
        content,
      );
    },
    {
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        refetch();
      },
    },
  );

  const postFindings = useMutation(
    [],
    async ({ inventoryModel, content_id, findingCuids }: PostFindingVars) => {
      return await API.PatchValidationGuidelineAssessmentFindings(
        inventoryModel,
        content_id,
        findingCuids,
      );
    },
    {
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        refetch();
      },
    },
  );

  const onResetAssessment = () => {
    setAssessmentOption(undefined);

    postAssessment.mutate({
      inventoryModel: inventoryModel!,
      content_id,
      assessmentOption: null,
    });
  };

  const onResetConfirmed = (confirmed: boolean) => {
    setResetRequested(false);
    if (confirmed) {
      onResetAssessment();
    }
  };

  const onSelectAssessment = (cuid: string) => {
    const option = guideline?.options?.find(option => option.cuid === cuid);
    setAssessmentOption(option!);

    postAssessment.mutate({
      inventoryModel: inventoryModel!,
      content_id,
      assessmentOption: option!,
    });
  };

  const onSelectedEvidences = (
    documentType: ModelDocumentTypeEnum,
    contents: TemplateSectionContents[],
  ) => {
    postEvidences.mutate({
      inventoryModel: inventoryModel!,
      content_id,
      documentType,
      contents,
    });
  };

  const onRemoveEvidence = (
    documentType: ModelDocumentTypeEnum,
    content: TemplateSectionContents,
  ) => {
    deleteEvidence.mutate({
      inventoryModel: inventoryModel!,
      content_id,
      documentType,
      content,
    });
  };

  const onSelectedFinding = (findingCuids: string[]) => {
    postFindings.mutate({
      inventoryModel: inventoryModel!,
      content_id,
      findingCuids,
    });
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      {!data && (
        <EmptyStateDisplay variant="no-activity">
          <Heading as={'h5'}>
            This Guideline (<code>{content_id}</code>) has been deleted.
          </Heading>
          <Text align={'center'}>
            Please remove this guideline from your Validation Report template.
          </Text>
        </EmptyStateDisplay>
      )}
      {!!data && (
        <Box mb={12}>
          <Heading as={'h3'} mb={4} id={`h-${content_id}`}>
            {data?.title}
          </Heading>

          <Stack spacing={8}>
            <Stack
              rounded={'md'}
              bg={useColorModeValue('white', 'neutral.900')}
              border={'1px solid'}
              borderTopColor={useColorModeValue('neutral.200', 'neutral.700')}
              borderBottomColor={useColorModeValue(
                'neutral.200',
                'neutral.700',
              )}
              borderRightColor={useColorModeValue('neutral.200', 'neutral.700')}
              borderLeftWidth={4}
              borderLeftColor={assessmentOption?.style.bg || 'neutral.200'}
              p={[4, 5]}
            >
              <Text fontWeight={'bold'}>Guideline</Text>
              <Text
                className={'ck ck-content'}
                dangerouslySetInnerHTML={{
                  __html: guideline?.description as string,
                }}
              ></Text>
              <Label>Assessment</Label>

              <Box>
                <Menu autoSelect={false} matchWidth gutter={0}>
                  {({ isOpen }) => (
                    <>
                      <HStack>
                        <MenuButton
                          isDisabled={readOnlyFields}
                          data-testid="assessment-option-menu-btn"
                          as={Button}
                          rightIcon={<ChevronDownIcon w={5} h={5} />}
                          borderWidth={1}
                          borderRadius="md"
                          w="full"
                          textAlign="left"
                          fontWeight={'normal'}
                        >
                          {assessmentOption ? (
                            <Text>{assessmentOption.name}</Text>
                          ) : (
                            <Text>Select a value for this guideline</Text>
                          )}
                        </MenuButton>
                        {assessmentOption && (
                          <>
                            <ConfirmationAlert
                              open={resetRequested}
                              title={`Reset guideline assessment`}
                              dialogBody={`Are you sure you'd like to reset this guideline's assessment?`}
                              cancelButton={'No, cancel'}
                              confirmButton={'Yes, reset'}
                              onConfirm={onResetConfirmed}
                            />
                            <Button
                              isDisabled={readOnlyFields}
                              variant="ghost"
                              onClick={() => setResetRequested(true)}
                              data-testid="add-finding-btn"
                              leftIcon={
                                <Icon as={ArrowUturnLeftIcon} boxSize={5} />
                              }
                              bg={'transparent'}
                              color={useColorModeValue(
                                'neutral.500',
                                'neutral.100',
                              )}
                              _hover={useColorModeValue(
                                { bg: 'neutral.100' },
                                { bg: 'neutral.800' },
                              )}
                            >
                              Reset
                            </Button>
                          </>
                        )}
                      </HStack>
                      <MenuList w={'full'} padding={0} boxShadow={'xl'}>
                        <MenuOptionGroup
                          onChange={cuid => {
                            onSelectAssessment(cuid as string);
                          }}
                          defaultValue={assessmentOption?.cuid}
                          value={assessmentOption?.cuid}
                          type="radio"
                        >
                          {guideline?.options
                            ?.sort((a, b) => a.order - b.order)
                            .map(option => (
                              <MenuItemOption
                                key={option.cuid}
                                value={option.cuid}
                                borderLeftColor={
                                  option?.style.bg || 'neutral.500'
                                }
                                borderLeftWidth={4}
                                py={2}
                              >
                                <Text>{option.name}</Text>
                                <Text fontSize={'sm'}>
                                  {option.description}
                                </Text>
                              </MenuItemOption>
                            ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </Stack>
            <Stack px={6} spacing={8}>
              {guideline?.require_notes && (
                <RiskAssessmentNotes
                  guideline={guideline}
                  readOnlyFields={readOnlyFields}
                />
              )}

              <Stack role="group">
                <HStack>
                  <Heading as={'h4'}>Developer Evidence</Heading>
                  <MoreInfoPopOver
                    title="Link Evidence to Reports"
                    link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-validation/assess-compliance.html#link-evidence-to-reports`}
                    iconProps={{
                      mt: '-2px',
                      opacity: 0,
                      _groupHover: { opacity: 1 },
                    }}
                  />
                  <Spacer />
                  <Button
                    isDisabled={readOnlyFields}
                    variant={'ghost'}
                    onClick={() => {
                      setShowAddEvidenceModal2(true);
                    }}
                    data-testid="add-qualitative-evidence"
                    leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                  >
                    {developerEvidences.length > 0
                      ? 'Manage Developer Evidence'
                      : 'Link Developer Evidence'}
                  </Button>
                </HStack>
                {developerEvidences && (
                  <EvidenceDocumentTree
                    documentType={ModelDocumentTypeEnum.model_documentation}
                    evidences={developerEvidences}
                    onRemoveEvidence={onRemoveEvidence}
                  />
                )}
              </Stack>

              {hasValidationReportResults && (
                <Stack>
                  <HStack>
                    <Heading as={'h4'}>Validator Evidence</Heading>
                    <Spacer />
                    <Button
                      isDisabled={readOnlyFields}
                      variant={'ghost'}
                      onClick={() => {
                        setShowAddEvidenceModal(true);
                      }}
                      data-testid="add-finding-btn"
                      leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                    >
                      Link Evidence to Report
                    </Button>
                  </HStack>
                  {validatorEvidences.length > 0 ? (
                    <Accordion allowToggle>
                      {validatorEvidences.map(evidence => (
                        <RiskAssessmentItemEvidenceItem
                          key={evidence.cuid}
                          evidence={evidence}
                        />
                      ))}
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </Stack>
              )}

              {!hasValidationReportResults && (
                <Stack>
                  <Heading as={'h4'}>Validator Evidence</Heading>
                  <Text mt={2} fontStyle={'oblique'} color={'neutral.500'}>
                    It looks like you don't have any test results logged as a
                    Validator.{' '}
                    <Link
                      color="brand.500"
                      href={`/model-inventory/${inventoryModel?.cuid}/getting-started`}
                    >
                      Click here to get started.
                    </Link>
                  </Text>
                </Stack>
              )}

              <Stack role="group">
                <HStack>
                  <Heading as={'h4'}>Findings</Heading>
                  <MoreInfoPopOver
                    title="Link Findings to Reports"
                    link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-validation/assess-compliance.html#link-findings-to-reports`}
                    iconProps={{
                      mt: '-2px',
                      opacity: 0,
                      _groupHover: { opacity: 1 },
                    }}
                  />
                  <Spacer />
                  <Button
                    isDisabled={readOnlyFields}
                    variant="ghost"
                    onClick={() => setShowAddFindingModal(true)}
                    data-testid="add-finding-btn"
                    leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                  >
                    Link Finding to Report
                  </Button>
                </HStack>

                {(guideline?.assessment?.findings || []).length > 0 ? (
                  <Accordion allowToggle>
                    <Stack spacing={1}>
                      {guideline?.assessment?.findings?.map(finding => (
                        <FindingRow
                          key={finding.cuid}
                          finding={finding.finding}
                        />
                      ))}
                    </Stack>
                  </Accordion>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>

          {/*This opens for developer evidence, selection of evidence via document tree navigation */}
          <RiskAssessmentAddEvidenceModal2
            isOpen={showAddEvidenceModal2}
            onClose={() => setShowAddEvidenceModal2(false)}
            evidences={developerEvidences}
            onSelectedEvidence={onSelectedEvidences}
          />

          {/*This opens for validator evidence, selection of evidence via tests list */}
          <RiskAssessmentAddEvidenceModal
            isOpen={showAddEvidenceModal}
            onClose={() => setShowAddEvidenceModal(false)}
            evidences={validatorEvidences}
            onSelectedEvidence={onSelectedEvidences}
            documentType={ModelDocumentTypeEnum.validation_report}
          />

          <RiskAssessmentAddFindingModal
            isOpen={showAddFindingModal}
            onClose={() => setShowAddFindingModal(false)}
            onSelectedFinding={onSelectedFinding}
            findingCuids={guideline?.assessment?.findings?.map(
              f => f.finding.cuid,
            )}
          />
        </Box>
      )}
    </LoadingContainer>
  );
}
