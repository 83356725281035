import {
  TInventoryModel,
  TInventoryModelUser,
  TInventoryModelUserRoles,
} from '../models/inventory_model';
import {
  ModelDocumentTypeEnum,
  getModelDocumentType,
} from '../models/model_document';
import { TUser, isAdmin } from '../models/user';
import { TUserRole } from '../models/role';

export const canUpdateFinding = (user: TUser) => {
  return (
    isAdmin(user) ||
    user.roles.some((item: TUserRole) =>
      ['Validator', 'Owner'].includes(item.role.name),
    )
  );
};

/**
 * Function to check if a user has the permission to update metadata.
 * This function checks if a user has the `UPDATE_METADATA` permission and if the user's role is
 * within the specified `allowedUserRoles` (Owners and Developers).
 *
 * @param {Object} user - The user for whom to check the permission.
 *
 * @param {TInventoryModelUser[]} users - Array of user objects where each object represents a user in the inventory model.
 *
 * @param {ModelDocumentTypeEnum} documentType - The type of document for which to check the permission.
 *
 * @returns {Boolean} - Returns true if the user has the permission to update metadata and the user's role is either 'Owners' or 'Developers'.
 * Otherwise, it returns false.
 */
export const canUpdateMetadata = (
  user: TUser,
  inventoryModelUsers: TInventoryModelUser[],
  documentType: ModelDocumentTypeEnum,
) => {
  let allowedUserRoles: TInventoryModelUserRoles[] = [];
  if (
    documentType === ModelDocumentTypeEnum.model_documentation ||
    documentType === ModelDocumentTypeEnum.monitoring
  ) {
    allowedUserRoles = [TInventoryModelUserRoles.Developers];
  } else if (documentType === ModelDocumentTypeEnum.validation_report) {
    allowedUserRoles = [TInventoryModelUserRoles.Validators];
  }
  return (
    isAdmin(user) ||
    inventoryModelUsers
      .filter(u => allowedUserRoles.includes(u.name))
      .some(u => u.user.cuid === user.cuid)
  );
};

/**
 * Checks if a user has the permission to update an inventory model.
 *
 * @param {User | undefined} user - The auth0 user whose permissions are to be checked.
 * If the user is undefined, the function will immediately return false.
 *
 * @param {TInventoryModelUser[]} users - An array of inventory model users.
 * The function checks if the user is included in this array. Be aware that you can filter
 * user model types such as Owners, Validators and Developers to make a more granular check.
 *
 * @returns {boolean} Returns true if the user exists, has the permission to
 * update an inventory model, and is included in the array of inventory model users.
 * Otherwise, it returns false.
 */
export const canUpdateInventoryModel = (
  user: TUser | undefined,
  inventoryModelUsers: TInventoryModelUser[],
) =>
  user &&
  (isAdmin(user) || inventoryModelUsers.some(u => u.user.cuid === user.cuid));

/**
 * Checks if a user is an owner of an inventory model. Admins are also considered owners.
 *
 * @param {User | undefined} user - The ValidMind user whose permissions are to be checked.
 * If the user is undefined, the function will immediately return false.
 * @param {TInventoryModel} inventoryModel - The inventory model whose users are to be checked.
 * The function checks if the user is included in the array of inventory model users and if
 * the user's role is 'Owners'.
 */
export const isInventoryModelOwner = (
  user: TUser | undefined,
  inventoryModel: TInventoryModel | undefined,
) =>
  user &&
  (isAdmin(user) ||
    (inventoryModel &&
      inventoryModel.users.some(
        u =>
          u.user.cuid === user.cuid &&
          u.name === TInventoryModelUserRoles.Owners,
      )));

/**
 * Checks if a user is a validator of an inventory model. Admins are also considered validators.
 *
 * @param {User | undefined} user - The ValidMind user whose permissions are to be checked.
 * If the user is undefined, the function will immediately return false.
 * @param {TInventoryModel} inventoryModel - The inventory model whose users are to be checked.
 * The function checks if the user is included in the array of inventory model users and if
 * the user's role is 'Validators'.
 */
export const isInventoryModelValidator = (
  user: TUser | undefined,
  inventoryModel: TInventoryModel | undefined,
) =>
  user &&
  (isAdmin(user) ||
    (inventoryModel &&
      inventoryModel.users.some(
        u =>
          u.user.cuid === user.cuid &&
          u.name === TInventoryModelUserRoles.Validators,
      )));

/**
 * Checks if a user is a developer of an inventory model. Admins are also considered developers.
 *
 * @param {User | undefined} user - The ValidMind user whose permissions are to be checked.
 * If the user is undefined, the function will immediately return false.
 * @param {TInventoryModel} inventoryModel - The inventory model whose users are to be checked.
 * The function checks if the user is included in the array of inventory model users and if
 * the user's role is 'Developers'.
 */
export const isInventoryModelDeveloper = (
  user: TUser | undefined,
  inventoryModel: TInventoryModel | undefined,
) =>
  user &&
  (isAdmin(user) ||
    (inventoryModel &&
      inventoryModel.users.some(
        u =>
          u.user.cuid === user.cuid &&
          u.name === TInventoryModelUserRoles.Developers,
      )));

/**
 * Checks if a user can update the a document. Only users with the role 'Developers' can
 * update a `model_documentation` document. Only users with the role 'Validators' can update
 * a `validation_report` document.
 *
 * *NOTE*: this is a more generalized version of the `canUpdateMetadata` function above since it doesn't
 * require the caller too pass a list of users. Instead, it only requires the inventory model and the user.
 *
 * @param {User} user - The user whose permissions are to be checked.
 * @param {TInventoryModel} inventoryModel - The inventory model whose users are to be checked.
 * @param {ModelDocumentTypeEnum} documentType - The type of document for which to check the permission.
 * @param {boolean} allowOwner - If true, the function will return true if the user is an owner of the inventory model.
 */
export const canUpdateDocument = (
  user: TUser | undefined | null,
  inventoryModel: TInventoryModel | undefined,
  documentTypeParam: string | undefined,
  allowOwner = false,
) => {
  if (!user || !inventoryModel || !documentTypeParam) return false;
  if (isAdmin(user)) return true;

  const documentType = getModelDocumentType(documentTypeParam);

  if (
    documentType === ModelDocumentTypeEnum.model_documentation ||
    documentType === ModelDocumentTypeEnum.monitoring
  ) {
    if (allowOwner) return isInventoryModelOwner(user, inventoryModel);
    return isInventoryModelDeveloper(user, inventoryModel);
  } else if (documentType === ModelDocumentTypeEnum.validation_report) {
    return isInventoryModelValidator(user, inventoryModel);
  }

  return false;
};
