import {
  Box,
  Fade,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
  Button,
  LinkBox,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../api/API';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import AddUseCaseModal from './AddUseCaseModal';
import UsersContext from '../../../contexts/UsersContext';
import { TUseCase } from '../../../models/use_case';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { DangerMenuItem } from '../../../components/DangerMenuItem';
import { CONFIG } from '../../../config';

interface UseCaseItemProps {
  item: TUseCase;
  canDelete: boolean;
  onDelete: (id: string) => void;
}

const UseCaseItem = forwardRef<HTMLDivElement, UseCaseItemProps>(
  function UseCaseItem({ item, canDelete, onDelete }, ref) {
    return (
      <LinkBox
        data-testid="business-unit-item"
        key={item.cuid}
        alignSelf={'stretch'}
        role="group"
        rounded={'md'}
        px={4}
        py={2}
        border={'1px solid'}
        _hover={{
          bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
          borderColor: useColorModeValue(
            'brandSecondary.100',
            'brandSecondary.800',
          ),
          color: useColorModeValue('inherit', 'brandSecondary.25'),
        }}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        transition={'all 0.3s ease-in-out'}
      >
        <Box w="full" ref={ref}>
          <Fade in={true} transition={{ enter: { duration: 0.5, delay: 0.2 } }}>
            <HStack w="full" rounded="md" role="group">
              <Text>{item.name}</Text>
              <Spacer />
              {canDelete && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    aria-label="Options"
                    icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                    visibility={'hidden'}
                    _groupHover={{
                      visibility: 'visible',
                    }}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  />
                  <MenuList>
                    <DangerMenuItem
                      icon={<Icon as={TrashIcon} boxSize={5} />}
                      onClick={event => {
                        onDelete(item.cuid);
                      }}
                    >
                      Delete Use Case
                    </DangerMenuItem>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </Fade>
        </Box>
      </LinkBox>
    );
  },
);

export function UseCasesPanel() {
  const { currentOrganization } = useContext(UsersContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userHasPermission } = useContext(UsersContext);
  const queryClient = useQueryClient();
  const toast = useToast();
  const [lastAddedItemId, setLastAddedItemId] = useState<string | null>(null);
  const newItemRef = useRef<HTMLDivElement>(null);

  const canReadUseCase = userHasPermission(['read_use_case'], 'all');
  const canAddUseCase = userHasPermission(['add_use_case'], 'all');
  const canDeleteUseCase = userHasPermission(['delete_use_case'], 'all');

  if (!canReadUseCase) {
    return null;
  }

  const { data: useCases = [] } = useQuery(['use-cases'], async () => {
    const response = (await API.GetOrganizationUseCases(currentOrganization))
      .results;
    return response;
  });

  const deleteUseCaseMutation = useMutation(
    [],
    async ({ cuid }: { cuid: string }) => {
      return API.DeleteOrganizationUseCase(currentOrganization, cuid);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('use-cases');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error deleting use case',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onSuccessUseCase = (data: TUseCase) => {
    setLastAddedItemId(data.cuid);
    onClose();
  };

  useEffect(() => {
    if (lastAddedItemId && newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setLastAddedItemId(null);
    }
  }, [lastAddedItemId, useCases]);

  const onDeleteUseCase = (cuid: string) => {
    deleteUseCaseMutation.mutate({ cuid });
  };

  return (
    <Stack
      w="full"
      gap={4}
      padding={4}
      border="1px solid"
      rounded="md"
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      h={'full'}
    >
      <HStack>
        <Heading as="h3">Use Cases</Heading>
        <Spacer />
        <MoreInfoPopOver
          title="Manage Use Cases"
          link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/set-up-your-organization.html#manage-use-cases`}
        />
      </HStack>
      <HStack spacing={1} w="full" data-testId="add-use-case">
        <Spacer />
        {canAddUseCase && (
          <Button
            data-testid="add-use-case-open-modal-button"
            onClick={onOpen}
            alignContent={'right'}
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            variant={'ghost'}
          >
            Add New Use Case
          </Button>
        )}
      </HStack>
      <VStack alignItems="flex-start" w="full" gap={4}>
        <Box
          scrollBehavior="smooth"
          overflowY="auto"
          w="full"
          h={'full'}
          maxH={96}
        >
          <VStack w="full" gap={1} data-testId="use-cases" h={'full'}>
            {useCases.map((useCase: TUseCase) => (
              <UseCaseItem
                ref={useCase.cuid === lastAddedItemId ? newItemRef : null}
                key={useCase.cuid}
                item={useCase}
                canDelete={canDeleteUseCase}
                onDelete={onDeleteUseCase}
              />
            ))}
          </VStack>
        </Box>
      </VStack>
      <AddUseCaseModal
        isOpen={isOpen}
        onSuccess={onSuccessUseCase}
        onClose={onClose}
      />
    </Stack>
  );
}
