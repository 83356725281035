import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import {
  Box,
  Stack,
  HStack,
  Icon,
  useDisclosure,
  Select,
  MenuItem,
  IconButton,
  As,
  Heading,
  VStack,
  Text,
  MenuDivider,
  Flex,
  Tabs,
  TabList,
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Link,
} from '@chakra-ui/react';
import './styles.css';
import {
  TrashIcon,
  PencilIcon,
  ArrowsPointingOutIcon,
  SquaresPlusIcon,
  ChartBarSquareIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import AddOrEditVisualizationModal from '../../components/AddOrEditVisualizationModal';
import { GridViewItem } from '../../components/GridView/GridViewItem';
import {
  TDashboard,
  TDashboardItem,
  TDashboardVisualization,
  TDashboardVisualizationJSON,
  TWidget,
} from '../../models/report';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import API from '../../api/API';
import { useQuery } from 'react-query';
import { WidthProvider } from 'react-grid-layout';
import ReactGridLayout from 'react-grid-layout';
import { AddIcon } from '@chakra-ui/icons';
import { AddToEndOfLayout } from '../../layoutUtils';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FindingsWidget from '../FindingsWidget';
import { UsersContext } from '../../contexts';
import ActivityFeedWidget from '../ActivityFeedWidget';
import FloatingActionButton from '../FloatingActionButton';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import { ViewVisualizationFullScreenModal } from './ViewVisualizationFullScreenModal';
import { AddOrEditDashboardModal } from './AddOrEditDashboardModal';
import AddVisualizationFromAnalytics from './AddVisualizationFromAnalytics';
import AddSavedViewToDashboardModal from './AddSavedViewToDashboardModal';
import { TSavedView } from '../../models/saved_view';
import RenderVisualization from '../Reporting/Visualizations/RenderVisualization';
import ModelInventoryTable from '../ModelInventoryTable';
import AddWidgetToDashboardModal from './AddWidgetToDashboardModal';
import FindingsTable from '../FindingsTable';
import CubesIcon from '../icons/CubesIcon';
import dayjs from 'dayjs';

const COLS = 4;

type DashboardSelectorProps = {
  selectedDashboard: TDashboard | undefined;
  onDashboardChange: (dashboard: TDashboard) => void;
  allDashboards?: TDashboard[];
};

export const DashboardSelector = ({
  selectedDashboard,
  onDashboardChange,
  allDashboards,
}: DashboardSelectorProps) => {
  return (
    <HStack direction="row" spacing={4}>
      <Select
        value={selectedDashboard?.cuid}
        onChange={e => {
          const selected = allDashboards?.find(
            dashboard => dashboard.cuid === e.target.value,
          );
          if (selected) {
            onDashboardChange(selected);
          }
        }}
      >
        {allDashboards?.map(dashboard => (
          <option key={dashboard.cuid} value={dashboard.cuid}>
            {dashboard.name}
          </option>
        ))}
      </Select>
    </HStack>
  );
};

function FloatingIconButton({ onClick }: { onClick?: () => void }) {
  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex="100">
      <IconButton
        icon={<AddIcon boxSize={6} />}
        variant={'primary'}
        aria-label="Add item"
        isRound
        shadow="lg"
        style={{
          height: '64px',
          width: '64px',
        }}
        onClick={onClick}
      />
    </Box>
  );
}

type TabProps = {
  isSelected: boolean;
  icon?: As;
  label: string;
  labelColor?: string;
  onClick?: () => void;
};

const Tab = ({ isSelected, icon, label, labelColor, onClick }: TabProps) => {
  return (
    <Box
      position={'relative'}
      top={'1px'}
      px={5}
      py={2}
      borderBottom={isSelected ? '2px solid' : 'none'}
      borderBottomColor={isSelected ? 'brand.500' : 'transparent'}
      color={isSelected ? 'brand.500' : labelColor || 'inherit'}
      cursor={'pointer'}
      onClick={onClick}
    >
      <HStack spacing={2}>
        {!!icon && <Icon as={icon} boxSize={3} />}
        <Box whiteSpace={'nowrap'}>{label}</Box>
      </HStack>
    </Box>
  );
};

type DashboardPageProps = {
  dashboardType: 'report' | 'user';
};

export default function DashboardPage({
  dashboardType,
}: DashboardPageProps): JSX.Element {
  const navigate = useNavigate();
  const addEditModal = useDisclosure();
  const deleteDashboardModal = useDisclosure();
  const createDashboardModal = useDisclosure();
  const viewVisualizationModal = useDisclosure();
  const addVisualizationFromAnalyticsModal = useDisclosure();
  const addModelSavedViewModal = useDisclosure();
  const addFindingSavedViewModal = useDisclosure();
  const deleteVisualizationModal = useDisclosure();
  const addWidgetModal = useDisclosure();
  const [selectedDashboard, setSelectedDashboard] = useState<TDashboard>();
  const [dashboardToEdit, setDashboardToEdit] = useState<TDashboard>();
  const [dashboardItemToEdit, setDashboardItemToEdit] = useState<
    TDashboardItem | undefined
  >();
  const [dashboards, setDashboards] = useState<TDashboard[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser: user, userHasPermission } = useContext(UsersContext);

  const [editMode, setEditMode] = useState(false);
  const [isStaleDashboard, setIsStaleDashboard] = useState(false);

  const checkForUpdatedDashboard = useCallback(async () => {
    if (!editMode || !selectedDashboard) {
      return;
    }

    const updatedDashboard = await API.GetDashboard(selectedDashboard!.cuid);

    if (
      dayjs(updatedDashboard.updated_at).isAfter(selectedDashboard!.updated_at)
    ) {
      addEditModal.onClose();
      deleteDashboardModal.onClose();
      createDashboardModal.onClose();
      addVisualizationFromAnalyticsModal.onClose();
      addModelSavedViewModal.onClose();
      addFindingSavedViewModal.onClose();
      deleteVisualizationModal.onClose();
      addWidgetModal.onClose();
      setIsStaleDashboard(true);
    } else {
      setIsStaleDashboard(false);
    }
  }, [editMode, selectedDashboard]);

  useEffect(() => {
    const polling = setInterval(checkForUpdatedDashboard, 1000);

    return () => clearInterval(polling);
  }, [checkForUpdatedDashboard]);

  useQuery(
    'dashboards',
    async () => {
      const fetchedDashboards = await API.GetDashboards(dashboardType);
      setDashboards(fetchedDashboards);

      // Set selected dashboard to the first one if none is selected
      if (fetchedDashboards.length > 0) {
        const dashboardCUID = searchParams.get('page');
        if (dashboardCUID) {
          const foundDashboard = fetchedDashboards.find(
            dashboard => dashboard.cuid === dashboardCUID,
          );
          if (foundDashboard) {
            setSelectedDashboard(foundDashboard);
            return;
          }
        }
        setSelectedDashboard(fetchedDashboards[0]);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const onSubmitVisualization = async (
    json: TDashboardVisualizationJSON,
    visualizationCuid?: string,
  ) => {
    const copyOfSelectedDashboard = { ...selectedDashboard! };

    // If the visualization is new, add it to the layout
    if (!visualizationCuid) {
      const layout = copyOfSelectedDashboard.layout;

      const visualization = await API.CreateDashboardVisualization(
        copyOfSelectedDashboard.cuid,
        json,
      );

      AddToEndOfLayout(layout, {
        i: visualization.cuid,
        w: 2,
        h: 2,
      });

      const updatedDashboard = await API.UpdateDashboard(
        copyOfSelectedDashboard.cuid,
        copyOfSelectedDashboard,
      );

      copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

      copyOfSelectedDashboard.dashboard_items.push({
        cuid: visualization.cuid,
        item: visualization,
        item_type: 'DashboardVisualization',
        is_reference: false,
      });
    } else {
      // If the visualization already exists, update the visualization
      const visualization = await API.UpdateDashboardVisualization(
        visualizationCuid,
        json,
      );

      // Update the dashboard so the updated_at is updated
      const updatedDashboard = await API.UpdateDashboard(
        copyOfSelectedDashboard.cuid,
        copyOfSelectedDashboard,
      );

      copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

      // Update the visualization in the dashboard_items array
      const copyOfDashboardItems = [...copyOfSelectedDashboard.dashboard_items];
      const index = copyOfDashboardItems.findIndex(
        i => i.item.cuid === visualization.cuid,
      );
      copyOfDashboardItems[index].item = visualization;
      copyOfSelectedDashboard.dashboard_items = copyOfDashboardItems;
    }
    setSelectedDashboard(copyOfSelectedDashboard);
    addEditModal.onClose();
  };

  const onDashboardSave = useCallback(
    async ({
      id,
      name,
      description,
    }: {
      id?: string;
      name: string;
      description: string;
    }) => {
      if (!id) {
        const newDashboard = await API.CreateDashboard({
          name,
          description,
          dashboard_type: dashboardType,
        });
        setDashboards([...dashboards, newDashboard]);
        setSelectedDashboard(newDashboard);
      } else {
        const updatedDashboard = await API.UpdateDashboard(id, {
          name,
          description,
        });
        const copyOfDashboards = [...dashboards];
        const index = copyOfDashboards.findIndex(d => d.cuid === id);
        copyOfDashboards[index] = updatedDashboard;
        setDashboards(copyOfDashboards);
        setSelectedDashboard(updatedDashboard);
      }
    },
    [dashboards, setDashboards],
  );

  useEffect(() => {
    if (selectedDashboard) {
      setSearchParams({ page: selectedDashboard.cuid });
    }
  }, [selectedDashboard]);

  // This is needed for some reason to make the grid work
  // otherwise new items to the grid won't have the correct Width and Height
  const MeasuredGridView = useMemo(
    () => WidthProvider(ReactGridLayout),
    [selectedDashboard],
  );

  const canEdit =
    userHasPermission(['update_analytics'], 'all') &&
    dashboardType === 'report' &&
    !!selectedDashboard &&
    !selectedDashboard.is_default;

  // Can only add dashboards or pages if the user has update_analytics permission
  // OR if they are in their own dashboard page.
  const canAdd =
    (userHasPermission(['update_analytics'], 'all') &&
      dashboardType === 'report' &&
      !!selectedDashboard) ||
    dashboardType === 'user';

  let addButtonMenu = [];

  if (dashboardType === 'user') {
    addButtonMenu = [
      {
        icon: <Icon as={CubesIcon} boxSize={6} />,
        label: 'Model Inventory View',
        onClick: () => {
          setDashboardItemToEdit(undefined);
          addModelSavedViewModal.onOpen();
        },
      },
      {
        icon: <Icon as={ExclamationTriangleIcon} boxSize={6} />,
        label: 'Model Findings View',
        onClick: () => {
          setDashboardItemToEdit(undefined);
          addFindingSavedViewModal.onOpen();
        },
      },
      {
        icon: <Icon as={ChartBarSquareIcon} boxSize={6} />,
        label: 'Analytics Visualization',
        onClick: () => {
          setDashboardItemToEdit(undefined);
          addVisualizationFromAnalyticsModal.onOpen();
        },
      },
      {
        icon: <Icon as={SquaresPlusIcon} boxSize={6} />,
        label: 'Other',
        onClick: () => {
          setDashboardItemToEdit(undefined);
          addWidgetModal.onOpen();
        },
      },
    ];
  } else {
    addButtonMenu = [
      {
        icon: <Icon as={AddIcon} boxSize={6} />,
        label: 'Add Visualization',
        onClick: () => {
          setDashboardItemToEdit(undefined);
          addEditModal.onOpen();
        },
      },
    ];
  }

  return (
    <>
      {!isStaleDashboard && (
        <AddWidgetToDashboardModal
          isOpen={addWidgetModal.isOpen}
          onClose={addWidgetModal.onClose}
          onSubmit={async (widget: TWidget) => {
            const copyOfSelectedDashboard = { ...selectedDashboard! };
            const layout = copyOfSelectedDashboard.layout;

            if (selectedDashboard) {
              const item = await API.CreateDashboardReference(
                selectedDashboard?.cuid,
                'Widget',
                widget.cuid,
              );

              AddToEndOfLayout(layout, {
                i: item.cuid,
                w: 2,
                h: 2,
              });

              const updatedDashboard = await API.UpdateDashboard(
                copyOfSelectedDashboard.cuid,
                copyOfSelectedDashboard,
              );

              copyOfSelectedDashboard.dashboard_items.push(item);
              copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

              setSelectedDashboard(copyOfSelectedDashboard);
              addWidgetModal.onClose();
            }
          }}
        />
      )}

      {!isStaleDashboard && (
        <AddSavedViewToDashboardModal
          type="model"
          isOpen={addModelSavedViewModal.isOpen}
          onClose={addModelSavedViewModal.onClose}
          onSubmit={async (view: TSavedView) => {
            const copyOfSelectedDashboard = { ...selectedDashboard! };
            const layout = copyOfSelectedDashboard.layout;

            if (selectedDashboard) {
              const item = await API.CreateDashboardReference(
                selectedDashboard?.cuid,
                'SavedView',
                view.cuid,
              );

              AddToEndOfLayout(layout, {
                i: item.cuid,
                w: 2,
                h: 2,
              });

              const updatedDashboard = await API.UpdateDashboard(
                copyOfSelectedDashboard.cuid,
                copyOfSelectedDashboard,
              );

              copyOfSelectedDashboard.dashboard_items.push({
                cuid: item.cuid,
                item: view,
                item_type: 'SavedView',
                is_reference: true,
              });
              copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

              setSelectedDashboard(copyOfSelectedDashboard);
              addModelSavedViewModal.onClose();
            }
          }}
        />
      )}
      {!isStaleDashboard && (
        <AddSavedViewToDashboardModal
          type="finding"
          isOpen={addFindingSavedViewModal.isOpen}
          onClose={addFindingSavedViewModal.onClose}
          onSubmit={async (view: TSavedView) => {
            const copyOfSelectedDashboard = { ...selectedDashboard! };
            const layout = copyOfSelectedDashboard.layout;

            if (selectedDashboard) {
              const item = await API.CreateDashboardReference(
                selectedDashboard?.cuid,
                'SavedView',
                view.cuid,
              );

              AddToEndOfLayout(layout, {
                i: item.cuid,
                w: 2,
                h: 2,
              });

              const updatedDashboard = await API.UpdateDashboard(
                copyOfSelectedDashboard.cuid,
                copyOfSelectedDashboard,
              );

              copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

              copyOfSelectedDashboard.dashboard_items.push({
                cuid: item.cuid,
                item: view,
                item_type: 'SavedView',
                is_reference: true,
              });

              setSelectedDashboard(copyOfSelectedDashboard);
              addFindingSavedViewModal.onClose();
            }
          }}
        />
      )}
      {!isStaleDashboard && (
        <AddVisualizationFromAnalytics
          isOpen={addVisualizationFromAnalyticsModal.isOpen}
          onClose={addVisualizationFromAnalyticsModal.onClose}
          onSubmit={async (vis: TDashboardVisualization) => {
            const copyOfSelectedDashboard = { ...selectedDashboard! };
            const layout = copyOfSelectedDashboard.layout;

            if (selectedDashboard) {
              const item = await API.CreateDashboardReference(
                selectedDashboard?.cuid,
                'DashboardVisualization',
                vis.cuid,
              );

              AddToEndOfLayout(layout, {
                i: item.cuid,
                w: 2,
                h: 2,
              });

              const updatedDashboard = await API.UpdateDashboard(
                copyOfSelectedDashboard.cuid,
                copyOfSelectedDashboard,
              );

              copyOfSelectedDashboard.dashboard_items.push({
                cuid: item.cuid,
                item: vis,
                item_type: 'DashboardVisualization',
                is_reference: true,
              });
              copyOfSelectedDashboard.updated_at = updatedDashboard.updated_at;

              setSelectedDashboard(copyOfSelectedDashboard);
              addVisualizationFromAnalyticsModal.onClose();
            }
          }}
        />
      )}
      {!isStaleDashboard && (
        <DeleteConfirmModal
          title={'Delete Page'}
          text="Are you sure you want to delete this page? This cannot be undone."
          isOpen={deleteDashboardModal.isOpen}
          onClose={deleteDashboardModal.onClose}
          onConfirm={async () => {
            const newDashboards = dashboards.filter(
              d => d.cuid !== selectedDashboard?.cuid,
            );
            await API.DeleteDashboard(selectedDashboard!.cuid);
            setDashboards(newDashboards);
            setSelectedDashboard(newDashboards[0]);
            deleteDashboardModal.onClose();
          }}
        />
      )}
      {!isStaleDashboard && (
        <DeleteConfirmModal
          title={`Delete ${dashboardType === 'user' ? 'Dashboard' : 'Page'}`}
          text="Are you sure you want to delete? This cannot be undone."
          isOpen={deleteDashboardModal.isOpen}
          onClose={deleteDashboardModal.onClose}
          onConfirm={async () => {
            const newDashboards = dashboards.filter(
              d => d.cuid !== selectedDashboard?.cuid,
            );
            await API.DeleteDashboard(selectedDashboard!.cuid);
            setDashboards(newDashboards);
            setSelectedDashboard(newDashboards[0]);
            deleteDashboardModal.onClose();
          }}
        />
      )}
      {!isStaleDashboard && (
        <DeleteConfirmModal
          title={`Delete ${dashboardType === 'user' ? 'Dashboard' : 'Page'}`}
          text={`Are you sure you want to delete this ${
            dashboardType === 'user' ? 'Dashboard' : 'Page'
          }? This cannot be undone.`}
          isOpen={deleteDashboardModal.isOpen}
          onClose={deleteDashboardModal.onClose}
          onConfirm={async () => {
            const newDashboards = dashboards.filter(
              d => d.cuid !== selectedDashboard?.cuid,
            );
            await API.DeleteDashboard(selectedDashboard!.cuid);
            setDashboards(newDashboards);
            setSelectedDashboard(newDashboards[0]);
            deleteDashboardModal.onClose();
          }}
        />
      )}
      {!isStaleDashboard && (
        <DeleteConfirmModal
          title="Delete"
          text={`${
            dashboardItemToEdit?.is_reference
              ? 'Are you sure you want to remove this from this dashboard?'
              : 'Are you sure you want to delete this? It will be removed from all pages and dashboards - including User Dashboards.'
          }`}
          isOpen={deleteVisualizationModal.isOpen}
          onClose={deleteVisualizationModal.onClose}
          onConfirm={async () => {
            if (selectedDashboard && dashboardItemToEdit) {
              const newLayout = selectedDashboard.layout.filter(
                l => l.i !== dashboardItemToEdit.cuid,
              );

              const copyOfSelectedDashboard = {
                ...selectedDashboard,
                layout: newLayout,
              };

              // If the visualization is a reference, remove the reference
              if (dashboardItemToEdit.is_reference) {
                API.DeleteDashboardReference(
                  selectedDashboard.cuid,
                  dashboardItemToEdit.cuid,
                );
              } else {
                // If the visualization is not a reference, delete the visualization
                API.DeleteDashboardVisualization(dashboardItemToEdit.cuid);
              }
              const updatedDashboard = await API.UpdateDashboard(
                copyOfSelectedDashboard.cuid,
                copyOfSelectedDashboard,
              );
              setSelectedDashboard(updatedDashboard);

              deleteVisualizationModal.onClose();
              setDashboardItemToEdit(undefined);
            }
          }}
        />
      )}
      <ViewVisualizationFullScreenModal
        isOpen={viewVisualizationModal.isOpen}
        onClose={viewVisualizationModal.onClose}
        visualizationJSON={(() => {
          if (
            dashboardItemToEdit &&
            dashboardItemToEdit.item_type === 'DashboardVisualization'
          ) {
            return (dashboardItemToEdit.item as TDashboardVisualization).json;
          }
          return undefined;
        })()}
      />
      {!isStaleDashboard && (
        <AddOrEditVisualizationModal
          isOpen={addEditModal.isOpen}
          onClose={addEditModal.onClose}
          onSubmit={onSubmitVisualization}
          existingVisualizationId={(() => {
            if (
              dashboardItemToEdit &&
              dashboardItemToEdit.item_type === 'DashboardVisualization'
            ) {
              return (dashboardItemToEdit.item as TDashboardVisualization).cuid;
            }
            return undefined;
          })()}
          existingVisualizationJSON={(() => {
            if (
              dashboardItemToEdit &&
              dashboardItemToEdit.item_type === 'DashboardVisualization'
            ) {
              return (dashboardItemToEdit.item as TDashboardVisualization).json;
            }
            return undefined;
          })()}
        />
      )}
      {!isStaleDashboard && (
        <AddOrEditDashboardModal
          type={dashboardType === 'user' ? 'Dashboard' : 'Page'}
          existingDashboard={dashboardToEdit}
          isOpen={createDashboardModal.isOpen}
          onClose={createDashboardModal.onClose}
          onSave={onDashboardSave}
        />
      )}

      <Stack w="full">
        <Tabs size={'lg'}>
          <TabList
            overflowY="hidden"
            sx={{
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {dashboards?.map(dashboard => (
              <Tab
                key={`tab-${dashboard.cuid}`}
                isSelected={selectedDashboard?.cuid === dashboard.cuid}
                onClick={() => {
                  setEditMode(false);
                  setIsStaleDashboard(false);
                  setSelectedDashboard(dashboard);
                }}
                label={dashboard.name}
              />
            ))}
            {canAdd && (
              <Tab
                key={`tab-add-dashboard`}
                isSelected={false}
                onClick={() => {
                  setDashboardToEdit(undefined);
                  createDashboardModal.onOpen();
                }}
                label={`Add ${dashboardType === 'user' ? 'Dashboard' : 'Page'}`}
                icon={AddIcon}
              />
            )}
          </TabList>
        </Tabs>
        {selectedDashboard && (
          <>
            <VStack alignItems={'flex-start'} px={4} pt={2}>
              {isStaleDashboard && editMode && (
                <Alert
                  position="absolute"
                  flexDirection="column"
                  bottom={10}
                  left="50%"
                  transform="translateX(-50%)"
                  zIndex={999999999}
                  status="error"
                  w={'50%'}
                  ml="auto"
                  mr="auto"
                >
                  <HStack gap={0}>
                    <AlertIcon />
                    <AlertTitle>
                      A new version of this{' '}
                      {dashboardType === 'report' ? 'page' : 'dashboard'} is
                      available.
                    </AlertTitle>
                  </HStack>
                  <AlertDescription>
                    <Link onClick={() => window.location.reload()}>Reload</Link>{' '}
                    this {dashboardType === 'report' ? 'page' : 'dashboard'} to
                    get the latest version and continue editing.
                  </AlertDescription>
                </Alert>
              )}
              <HStack justifyContent={'space-between'} w={'100%'}>
                <Heading as={'h2'}>{selectedDashboard.name}</Heading>
                <HStack>
                  {editMode && canAdd && (
                    <FloatingActionButton menuItems={addButtonMenu} />
                  )}
                  {(canEdit || dashboardType === 'user') && editMode && (
                    <>
                      <Button
                        variant={'ghost'}
                        leftIcon={<Icon as={PencilIcon} boxSize={6} />}
                        onClick={() => {
                          setDashboardToEdit(selectedDashboard);
                          createDashboardModal.onOpen();
                        }}
                      >
                        Details
                      </Button>
                      <Button
                        variant={'ghost'}
                        leftIcon={<Icon as={TrashIcon} boxSize={6} />}
                        onClick={async () => {
                          deleteDashboardModal.onOpen();
                        }}
                        _hover={{
                          backgroundColor: 'red.25 !important',
                          color: 'red.700',
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  {(canEdit || dashboardType === 'user') && (
                    <Button
                      variant={editMode ? 'primary' : 'outline'}
                      leftIcon={
                        editMode ? <></> : <Icon as={PencilIcon} boxSize={6} />
                      }
                      onClick={() => setEditMode(!editMode)}
                    >
                      {editMode ? 'Done Editing' : 'Edit'}
                    </Button>
                  )}
                </HStack>
              </HStack>
              {selectedDashboard.description && (
                <Text w={['100%', null, null, null, null, '75%']}>
                  {selectedDashboard.description}
                </Text>
              )}
            </VStack>
            <MeasuredGridView
              isDraggable={editMode && !isStaleDashboard}
              isResizable={editMode && !isStaleDashboard}
              key={selectedDashboard.cuid}
              rowHeight={200}
              cols={COLS}
              layout={selectedDashboard.layout}
              onLayoutChange={async layout => {
                const cleanedDashboardLayout = selectedDashboard.layout.map(
                  l => ({
                    i: l.i,
                    x: l.x,
                    y: l.y,
                    w: l.w,
                    h: l.h,
                  }),
                );

                const cleanedNewLayout = layout.map(l => ({
                  i: l.i,
                  x: l.x,
                  y: l.y,
                  w: l.w,
                  h: l.h,
                }));

                if (_.isEqual(cleanedDashboardLayout, cleanedNewLayout)) {
                  console.log('Layouts are the same, not saving');
                  return;
                }

                // Fail-safe to prevent empty layouts from overwriting the dashboard
                if (
                  selectedDashboard &&
                  selectedDashboard.layout.length === layout.length
                ) {
                  // Don't wait for API response to update the UI
                  const updatedDashboard = await API.UpdateDashboard(
                    selectedDashboard.cuid,
                    {
                      ...selectedDashboard,
                      layout,
                    },
                  );

                  selectedDashboard.layout = layout;
                  selectedDashboard.updated_at = updatedDashboard.updated_at;
                }
              }}
              autoSize={true}
            >
              {selectedDashboard.layout.map(item => {
                const dashboardItem = selectedDashboard.dashboard_items.find(
                  (i: TDashboardItem) => {
                    return i.cuid === item.i;
                  },
                );

                let menuItems = [];

                let RenderComponent = undefined;
                let title = 'Title';

                if (!dashboardItem) {
                  title = '';
                  RenderComponent = (
                    <Flex
                      h={'100%'}
                      w={'100%'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Box
                        p={4}
                        bg={'red.100'}
                        color={'red.900'}
                        borderRadius={8}
                      >
                        This item has been deleted.
                      </Box>
                    </Flex>
                  );
                }

                if (dashboardItem?.item_type === 'DashboardVisualization') {
                  const visualization =
                    dashboardItem.item as TDashboardVisualization;

                  title = visualization.json.title;

                  RenderComponent = (
                    <RenderVisualization
                      isEditing={false}
                      visualizationJSON={visualization.json}
                    />
                  );

                  if (
                    visualization.json.type === 'bar-chart' ||
                    visualization.json.type === 'pie-chart'
                  ) {
                    menuItems.push(
                      <MenuItem
                        icon={<Icon as={ArrowsPointingOutIcon} boxSize={4} />}
                        onClick={() => {
                          setDashboardItemToEdit(dashboardItem);
                          viewVisualizationModal.onOpen();
                        }}
                      >
                        View Full Screen
                      </MenuItem>,
                    );
                  }

                  if (editMode && canEdit) {
                    menuItems.push(
                      <MenuItem
                        icon={<Icon as={PencilIcon} boxSize={4} />}
                        onClick={() => {
                          setDashboardItemToEdit(dashboardItem);
                          addEditModal.onOpen();
                        }}
                      >
                        Edit Visualization
                      </MenuItem>,
                    );
                  }
                }

                if (dashboardItem?.item_type === 'Widget') {
                  const widget = dashboardItem.item as TWidget;
                  if (widget.type === 'activity-feed') {
                    title = 'Recent Activity';
                    RenderComponent = (
                      <ActivityFeedWidget variant={'user-summary'} />
                    );
                  } else if (widget.type === 'model-findings') {
                    title = 'Model Findings';
                    RenderComponent = (
                      <FindingsWidget
                        variant={'user-summary'}
                        user={user || undefined}
                      />
                    );
                  }
                }

                if (dashboardItem?.item_type === 'SavedView') {
                  const view = dashboardItem.item as TSavedView;
                  title = view.name;
                  if (view.type === 'model') {
                    RenderComponent = (
                      <ModelInventoryTable
                        displayTable
                        filters={view.content.filters}
                        sortBy={view.content.sortBy}
                        columns={view.content.columns}
                        onClickRow={(row: any) => {
                          navigate(
                            `/model-inventory/${row.original.cuid}/overview`,
                          );
                        }}
                      />
                    );
                  }

                  if (view.type === 'finding') {
                    RenderComponent = (
                      <FindingsTable
                        filters={view.content.filters}
                        sortBy={view.content.sortBy}
                        columns={view.content.columns}
                        onClickRow={row => {
                          navigate(
                            `/model-inventory/${row.original.inventory_model.cuid}/findings/${row.original.cuid}`,
                          );
                        }}
                      />
                    );
                  }
                }

                // Users can always remove items from their own dashboard
                if (editMode && (canEdit || dashboardType === 'user')) {
                  if (menuItems.length > 0) {
                    menuItems.push(<MenuDivider />);
                  }
                  menuItems.push(
                    <MenuItem
                      color="red.500"
                      icon={<Icon as={TrashIcon} boxSize={4} />}
                      onClick={async () => {
                        if (dashboardItem) {
                          setDashboardItemToEdit(dashboardItem);
                          deleteVisualizationModal.onOpen();
                        } else {
                          // delete the layout item
                          const newLayout = selectedDashboard.layout.filter(
                            l => l.i !== item.i,
                          );
                          const updatedDashboard = await API.UpdateDashboard(
                            selectedDashboard.cuid,
                            {
                              ...selectedDashboard,
                              layout: newLayout,
                            },
                          );
                          setSelectedDashboard({
                            ...selectedDashboard,
                            layout: newLayout,
                            updated_at: updatedDashboard.updated_at,
                          });
                        }
                      }}
                    >
                      Remove
                    </MenuItem>,
                  );
                }

                return (
                  <GridViewItem
                    key={item.i}
                    title={title}
                    isDraggable={editMode && !isStaleDashboard}
                    menuItems={menuItems.length > 0 ? menuItems : undefined}
                  >
                    {RenderComponent}
                  </GridViewItem>
                );
              })}
            </MeasuredGridView>
          </>
        )}
      </Stack>
    </>
  );
}
