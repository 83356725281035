import {
  VStack,
  Heading,
  Text,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import ValidMindLogo from '../../components/ValidMindLogo';

const InternalServerError = () => {
  return (
    <Box
      height="100vh"
      bg={useColorModeValue('neutral.25', 'black')}
      color={useColorModeValue('neutral.800', 'white')}
      pt="30vh"
    >
      <VStack spacing={12} maxW="container.sm" px={8} mx="auto">
        <Box color="brand.base">
          <ValidMindLogo />
        </Box>

        <VStack spacing={6}>
          <Heading as="h1" size="xl">
            500 Internal Server Error
          </Heading>
          <Text fontSize="lg" textAlign="center" maxWidth="prose">
            Server not responding, please contact server administrator.
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default InternalServerError;

export {}; 