import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  useToast,
  FormControl,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import AdminAPI, { Organization } from '../../api/AdminAPI';
import FormSelect from '../pages/FormSelect';
import { TInventoryModel } from '../../models/inventory_model';
import { CheckIcon } from '@chakra-ui/icons';

interface ExportOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

export function ExportOrganizationModal({
  isOpen,
  onClose,
  organization,
}: ExportOrganizationModalProps) {
  const fileDownloadRef = useRef<HTMLAnchorElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [name, setName] = useState('');
  const [selectedModels, setSelectedModels] = useState<TInventoryModel[]>([]);
  const [modelInventory, setModelInventory] = useState<TInventoryModel[]>([]);
  const { isLoading } = useQuery(
    ['inventory-models'],
    async () => {
      return await AdminAPI.GetOrganizationInventoryModels(organization.cuid);
    },
    {
      onSuccess: data => setModelInventory(data),
      onError: err => {
        // track errors
      },
    },
  );
  const toast = useToast();

  useEffect(() => {
    if (organization && isOpen) {
      setName(organization.name);
    }
  }, [organization, isOpen]);

  const handleChangeModelValues = (modelNames: string[]) => {
    if (modelInventory) {
      const newModels = modelInventory.filter(model =>
        modelNames.includes(model.name),
      );
      setSelectedModels(newModels);
    }
  };

  const handleSelectAllModels = () => {
    if (modelInventory) {
      setSelectedModels(
        selectedModels.length === modelInventory.length ? [] : modelInventory,
      );
    }
  };

  const handleSubmit = async () => {
    try {
      setDownloading(true);
      let response;
      response = await AdminAPI.ExportOrganizationTemplate(organization.cuid, {
        inventory_model_cuids: selectedModels?.map(model => model.cuid),
      });
      const blobUrl = URL.createObjectURL(new Blob([response]));
      setDownloadUrl(blobUrl);

      // fileDownloadRef.current?.click(); can run before downloadFilename new state is ready
      setTimeout(() => {
        fileDownloadRef.current?.click();
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (e) {
      toast({
        title: 'Error downloading current organization template',
        description: 'Failed to download organization template',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloading(false);
    }
  };

  const handleClose = () => {
    setName('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Organization Template for {name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Text color="gray.600" fontSize="md">
              Export a template file that can be used to create new
              organizations. The template will include:
            </Text>

            <List spacing={2} pl={4}>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">Sample models (selected below)</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default risk areas and validation guidelines
                </Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default set of templates (documentation, validation report and
                  ongoing monitoring)
                </Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default set of model inventory fields
                </Text>
              </ListItem>
            </List>

            <FormControl isRequired isDisabled={isLoading}>
              <FormSelect
                label="Models to Export"
                selectedItems={selectedModels}
                options={
                  modelInventory
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map(model => ({
                      value: model.name,
                      label: model.name,
                    })) || []
                }
                onChange={(newValues: string[]) =>
                  handleChangeModelValues(newValues)
                }
                onSelectAll={handleSelectAllModels}
                allSelected={selectedModels.length === modelInventory?.length}
                isTemplate={false}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <a
            href={downloadUrl}
            hidden
            ref={fileDownloadRef}
            download={`${organization.name}_org_template.json`}
          />
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={downloading}
            isDisabled={!name}
          >
            Export Organization Template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
