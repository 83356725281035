import { TOrganization } from '../api/API';
import { TGroup } from './group';
import { TRole, TUserRole } from './role';
import { Layout } from 'react-grid-layout';

export type Onboarding = {
  collaborateDocumentation?: boolean;
  findResources?: boolean;
  getAccount?: boolean;
  isDismissed?: boolean;
  registerModel?: boolean;
  startDocumentation?: boolean;
  submitDocumentation?: boolean;
};

type ColumnsSchema = {
  version: string;
  columns: string[];
};

export type ReportLayouts = {
  version: number;
  findings: {
    lg: Layout[];
  };
  models: {
    lg: Layout[];
  };
};

export type DashboardLayouts = {
  version: number;
  dashboard: {
    lg: Layout[];
  };
};

export type UISettings = {
  onboarding?: Onboarding;
  colorMode?: string;
  modelInventoryColumns?: ColumnsSchema;
  modelFindingsColumns?: ColumnsSchema;
  displayTableModel?: boolean;
  hideVMInsightsSidebar?: boolean;
  reportLayouts?: ReportLayouts;
  dashboardLayouts?: DashboardLayouts;
};

type APIClient = {
  api_key: string;
  api_secret: string;
  organization_cuid: string;
};

export type TUser = {
  cuid: string;
  email: string;
  name: string;
  last_name: string;
  job_title: string;
  picture: string;
  roles: TUserRole[];
  organizations: TOrganization[];
  auth0_user_id?: string;
  api_clients: APIClient[];
  ui_settings: UISettings;
  // If user does not belong to an organization, they are redirected to onboarding
  belongs_to_org: boolean;
  accepted_tyc?: number;
  accepted_ai_terms?: number;
  is_super_user?: boolean;
};

export function getUserRolesForOrg(
  user: TUser | null | undefined,
  org: string | undefined,
): TUserRole[] {
  if (!user || !org) {
    return [];
  }
  let filteredScopes = [null, '', 'Organization', 'General'];
  let roles: TUserRole[] = user.roles.filter(
    r =>
      r.role.organization_cuid === org && filteredScopes.includes(r.role.scope),
  );

  return roles;
}

export function isAdmin(user: TUser): boolean {
  return user.roles.some(role => role.role.is_admin === true);
}

export type TUserWithoutRoles = Omit<TUser, 'roles'>;

export type TUserInvite = {
  cuid: string;
  email: string;
  status: string;
  roles: TRole[];
  groups: TGroup[];
  joined_date?: number;
  joined_user?: TUser;
  invited_by_user: TUser;
  created_at: number;
  updated_at?: number;
};
