import {
  Box,
  Button,
  Icon,
  Link,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import {
  ArrowLeftEndOnRectangleIcon,
  CogIcon,
  UsersIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { Flex } from '@chakra-ui/react';
import ValidMindLogo from '../../ValidMindLogo';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

// Define sidebar items
const SidebarItems = [
  // {
  //   label: 'Home',
  //   icon: HomeIcon,
  //   path: '/admin',
  // },
  {
    label: 'Organizations',
    icon: UsersIcon,
    path: '/admin/organizations',
  },
  {
    label: 'Users',
    icon: UsersIcon,
    path: '/admin/users',
  },
  {
    label: 'Organization Defaults',
    icon: CogIcon,
    path: '/admin/organization-defaults',
  },
  {
    label: 'Tools',
    icon: UsersIcon,
    path: '/admin/tools',
  },
  {
    label: 'Settings',
    icon: Cog6ToothIcon,
    path: '/admin/settings',
  },
];

const AdminLayout = ({ children }: any) => {
  const { signoutRedirect } = useAuth();
  const location = useLocation();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const onLogout = () => {
    signoutRedirect();
  };

  // Update this function to check if a path is active
  const isPathActive = (itemPath: string) => {
    // For organization and user routes, match both the list and detail views
    if (itemPath === '/admin/organizations' || itemPath === '/admin/users') {
      return location.pathname.startsWith(itemPath);
    }
    // For other routes, exact match
    return location.pathname === itemPath;
  };

  return (
    <Flex h="100vh">
      {/* Sidebar */}
      <Box
        w="15%"
        maxW="400px"
        bg={bgColor}
        borderRight="1px"
        borderColor={borderColor}
        py={8}
        position="fixed"
        h="full"
        display="flex"
        flexDirection="column"
      >
        <Box px={4} mb={8}>
          <ValidMindLogo />
        </Box>

        <VStack spacing={2} align="stretch" flex="1">
          {SidebarItems.map(item => (
            <Link
              key={item.path}
              as={RouterLink}
              to={item.path}
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ textDecoration: 'none' }}
            >
              <Flex
                align="center"
                p={3}
                mx={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={isPathActive(item.path) ? 'brand.base' : 'transparent'}
                color={isPathActive(item.path) ? 'white' : 'gray.700'}
                _hover={{
                  bg: isPathActive(item.path) ? 'brand.500' : 'neutral.100',
                }}
              >
                <Icon
                  as={item.icon}
                  mr={4}
                  w={4}
                  h={4}
                  color={isPathActive(item.path) ? 'white' : 'brand.base'}
                />
                <Text fontSize="sm" fontWeight="medium">
                  {item.label}
                </Text>
              </Flex>
            </Link>
          ))}
        </VStack>

        {/* Logout Button */}
        <Box px={4} mt="auto" mb={4}>
          <Button
            width="full"
            variant="ghost"
            onClick={onLogout}
            leftIcon={<Icon as={ArrowLeftEndOnRectangleIcon} w={5} h={5} />}
            justifyContent="flex-start"
          >
            Logout
          </Button>
        </Box>
      </Box>

      {/* Main Content */}
      <Box ml="15%" w="85%" p={8}>
        {children}
      </Box>
    </Flex>
  );
};

export default AdminLayout;
