import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AdminAPI from '../../api/AdminAPI';
import { Spinner, Center } from '@chakra-ui/react';

const OnboardingGuard = ({ children }: { children: React.ReactNode }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isOnboardingEnabled, setIsOnboardingEnabled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const checkOnboardingStatus = async () => {
            try {
                const settings = await AdminAPI.GetGeneralSettings();
                setIsOnboardingEnabled(settings.allow_user_onboarding);
            } catch (error) {
                console.error('Failed to check onboarding status:', error);
                setIsOnboardingEnabled(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkOnboardingStatus();
    }, []);

    if (isLoading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" color="brand.500" />
            </Center>
        );
    }

    if (!isOnboardingEnabled) {
        return <Navigate to="/onboarding-disabled" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

export default OnboardingGuard; 