import axios from 'axios';
import { CONFIG } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { TInventoryModel } from '../models/inventory_model';

// Create a custom event name
export const UNAUTHORIZED_EVENT = 'admin-unauthorized';

const axiosInstance = axios.create({
  baseURL: `${CONFIG.REACT_APP_API_URL}`,
  headers: {
    'X-Application': 'Admin',
  },
});

export let getAccessToken: (() => string | undefined) | null = null;

export const setTokenRetrievalMethod = (
  tokenRetrievalMethod: () => string | undefined,
) => {
  getAccessToken = tokenRetrievalMethod;
};

axiosInstance.interceptors.request.use(async config => {
  // Always add X-Request-Id header with UUID
  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4(),
    'X-Application': 'Admin',
  };

  // Add authorization header if available
  if (getAccessToken) {
    try {
      const token = getAccessToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error('Failed to get access token:', error);
    }
  }

  return config;
});

// Update the interceptor to emit an event instead of redirecting
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const errorData = error.response?.data;

    if (
      (error.response?.status === 400 && errorData?.code === 'unauthorized') ||
      error.response?.status === 403
    ) {
      // Emit custom event instead of redirecting
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT));
    }

    return Promise.reject(error);
  },
);

export type Role = {
  cuid: string;
  name: string;
  description: string;
  is_default_role: boolean;
  organization_cuid: string;
  is_admin?: boolean;
  is_staff?: boolean;
  scope: string;
  created_at: number;
  updated_at: number;
};

export type Organization = {
  cuid: string;
  name: string;
  roles: Role[];
  created_at: string;
  updated_at: string;
  feature_flags?: Record<string, any>;
  subscription?: {
    cuid: string;
    name: string;
    type: string;
  } | null;
  inventory_model_schema?: Record<string, any>;
  oidc_mappings?: {
    role_mappings: {
      [key: string]: string; // external group name (from ID token claims) -> role cuid
    };
  };
};

export type OrgAdminUser = {
  email: string;
  name: string;
  password?: string;
};

export type DefaultTemplateConfig = {
  model_documentation: string[];
  validation_report: string[];
  monitoring: string[];
};

export type CreateOrganizationRequest = {
  name: string;
  demo_inventory_models: string[];
  admin_user: OrgAdminUser;
  initial_templates: DefaultTemplateConfig;
  populate_demo_models: boolean;
};

export type DemoModel = {
  name: string;
  business_area: string;
  description: string;
};

export type Template = {
  id: string;
  name: string;
  description: string;
};

export type DefaultTemplate = {
  model_documentation: Template[];
  validation_report: Template[];
  monitoring: Template[];
};

export type OrganizationDefaults = {
  initial_demo_models: DemoModel[];
  initial_templates: DefaultTemplate;
};

export type UserRole = {
  cuid: string;
  role: Role;
  user: {
    cuid: string;
  };
  scope: string;
};

export type PostUserRequest = {
  name: string;
  last_name: string;
  email: string;
  password?: string;
  organization: {
    organization_cuid: string;
    roles: string[];
  };
};

export type PatchUserRequest = {
  name: string;
  last_name: string;
  password?: string;
  email: string;
  organization: {
    organization_cuid: string;
    roles: string[];
  };
};

type APIClient = {
  api_key: string;
  api_secret: string;
  organization_cuid: string;
};

export type User = {
  cuid: string;
  name: string;
  last_name: string | null;
  job_title: string | null;
  picture: string;
  email: string;
  organizations: Organization[];
  api_clients: APIClient[];
  roles: UserRole[];
  accepted_tyc: number | null;
  accepted_ai_terms: number | null;
  created_at: string;
  updated_at: string;
};

export type AdminUser = {
  cuid: string;
  user_cuid: string;
  role: string;
  email: string;
  name: string;
  last_name: string;
  created_at: string;
  updated_at: string;
};

export type ApiClient = {
  api_key: string;
  api_secret: string;
  organization_cuid: string;
  created_at: string;
  // ... any other fields returned by the API
};

export type OIDCRoleMappings = {
  role_mappings: {
    [key: string]: string; // external group name -> role cuid
  };
};

export type PatchOrganizationRequest = {
  name?: string;
  oidc_mappings?: {
    role_mappings: OIDCRoleMappings['role_mappings'];
  };
};

export interface GeneralSettings {
    allow_user_onboarding: boolean;
    sync_oidc_roles: boolean;
}

export type ExportOrganizationTemplateRequest = {
  inventory_model_cuids?: string[];
}

export type UpdateGeneralSettingsRequest = Partial<GeneralSettings>;

const GetOrganizations = async () => {
  const response = await axiosInstance.get<Organization[]>(
    '/admin/organizations',
  );
  return response.data;
};

// Add the new method in the same file after GetOrganizations
const GetOrganizationDefaults = async () => {
  const response = await axiosInstance.get<OrganizationDefaults>(
    '/admin/organizations/defaults',
  );
  return response.data;
};

const CreateOrganization = async (
  organizationData: CreateOrganizationRequest,
) => {
  const response = await axiosInstance.post<Organization>(
    '/admin/organizations',
    organizationData,
  );
  return response.data;
};

const GetUsers = async (): Promise<User[]> => {
  const response = await axiosInstance.get<User[]>('/admin/users');
  return response.data;
};

const PostUser = async (userData: PostUserRequest): Promise<User> => {
  const response = await axiosInstance.post<User>('/admin/users', userData);
  return response.data;
};

const PatchUser = async (
  userId: string,
  patchData: PatchUserRequest,
): Promise<User> => {
  const response = await axiosInstance.patch<User>(
    `/admin/users/${userId}`,
    patchData,
  );
  return response.data;
};

const PatchRBAC = async (action: string): Promise<User> => {
  const response = await axiosInstance.patch<User>('/admin/rbac', { action });
  return response.data;
};

const GetCurrentUser = async (): Promise<AdminUser> => {
  const result = await axiosInstance.get<AdminUser>('/admin/current_user');
  return result.data;
};

const RevokeUserApiCredentials = async (
  userCuid: string,
  apiKey: string,
): Promise<ApiClient> => {
  const response = await axiosInstance.post<ApiClient>(
    `/admin/users/${userCuid}/api_credentials/${apiKey}`,
  );
  return response.data;
};

const PatchOrganization = async (
  organizationCuid: string,
  data: PatchOrganizationRequest,
): Promise<Organization> => {
  const response = await axiosInstance.patch<Organization>(
    `/admin/organizations/${organizationCuid}`,
    data,
  );
  return response.data;
};

const UploadDefaultOrganizationTemplate = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axiosInstance.post<string>(
    `/admin/organizations/defaults/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

const GetDefaultOrganizationTemplate = async () => {
  const response = await axiosInstance.get<string>(
    `/admin/organizations/defaults/template`,
    { responseType: 'blob' },
  );
  return response.data;
};

const ExportOrganizationTemplate = async (organizationCuid: string, inventory_model_cuids: ExportOrganizationTemplateRequest) => {
  const response = await axiosInstance.patch<string>(
    `/admin/organizations/${organizationCuid}/defaults/template`,
    inventory_model_cuids,
    { responseType: 'blob' },
  );
  return response.data;
}

const UpdateGeneralSettings = async (
  settings: UpdateGeneralSettingsRequest,
): Promise<GeneralSettings> => {
  const response = await axiosInstance.patch<GeneralSettings>(
    '/admin/settings/general',
    settings,
  );
  return response.data;
};

const GetGeneralSettings = async (): Promise<GeneralSettings> => {
  const response = await axiosInstance.get<GeneralSettings>(
    '/admin/settings/general',
  );
  return response.data;
};

const GetBootstrapStatus = async (): Promise<{ isBootstrapped: boolean }> => {
  const result = await axiosInstance.get('/admin/organizations/bootstrap');
  return result.data;
};

const CreateBootstrapOrganization = async (data: {
  organization_name: string;
  admin_user: {
    email: string;
    first_name: string;
    last_name: string;
    password?: string;
  };
}): Promise<void> => {
  await axiosInstance.post('/admin/organizations/bootstrap', data);
};

const GetAdminUsers = async (): Promise<AdminUser[]> => {
  const response = await axiosInstance.get<AdminUser[]>('/admin/admin_users');
  return response.data;
};

// Add this type for the create admin user request
export type CreateAdminUserRequest = {
  user_cuid: string;
  name: string;
  last_name: string;
  email: string;
};

// Add the new API method
const CreateAdminUser = async (
  data: CreateAdminUserRequest,
): Promise<AdminUser> => {
  const response = await axiosInstance.post<AdminUser>(
    '/admin/admin_users',
    data,
  );
  return response.data;
};

// Add this function to handle user search
const SearchUsers = async (query: string): Promise<User[]> => {
  const response = await axiosInstance.get<User[]>(
    `/admin/users/search?q=${encodeURIComponent(query)}`,
  );
  return response.data;
};

const GetOrganizationInventoryModels = async (organizationCuid: string): Promise<TInventoryModel[]> => {
  const response = await axiosInstance.get(
    `/admin/organizations/${organizationCuid}/inventory_models`,
  );
  return response.data;
}

type DiagnosticsResponse = {
  cuid: string;
};

const CreateDiagnosticsRun = async (): Promise<string> => {
  const response = await axiosInstance.post<DiagnosticsResponse>(
    '/admin/diagnostics',
  );
  return response.data.cuid;
};

type DiagnosticsComponent = {
  component: string;
  data: Record<string, any>;
  status: string;
};

type DiagnosticsRun = {
  cuid: string;
  diagnostics: DiagnosticsComponent[];
};

const GetDiagnosticsRun = async (
  runId: string,
): Promise<DiagnosticsRun | null> => {
  const response = await axiosInstance.get<DiagnosticsRun[]>(
    '/admin/diagnostics',
  );
  const run = response.data.find(run => run.cuid === runId);
  return run || null;
};

export default {
  GetOrganizations,
  GetOrganizationDefaults,
  GetBootstrapStatus,
  CreateBootstrapOrganization,
  CreateOrganization,
  GetUsers,
  PostUser,
  PatchUser,
  PatchRBAC,
  GetCurrentUser,
  RevokeUserApiCredentials,
  PatchOrganization,
  UploadDefaultOrganizationTemplate,
  GetDefaultOrganizationTemplate,
  ExportOrganizationTemplate,
  UpdateGeneralSettings,
  GetGeneralSettings,
  GetAdminUsers,
  CreateAdminUser,
  SearchUsers,
  GetOrganizationInventoryModels,
  CreateDiagnosticsRun,
  GetDiagnosticsRun,
};
