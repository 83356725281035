import { useContext, useState } from 'react';
import InventoryModelContext from '../../../../contexts/InventoryModel';
import MasterStatusBar from '../../../../components/Layout/MasterStatusBar';
import MasterHeader from '../../../../components/Layout/MasterHeader';
import { useQuery } from 'react-query';
import API from '../../../../api/API';
import FindingsList from '../../../../components/FindingsList';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import { TFinding } from '../../../../models/finding';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import { Copyright } from '../../../../components/Copyright';
import { EmptyStateDisplay } from '../../../../components/EmptyStateDisplay';
import { Heading } from '@chakra-ui/react';
import MasterSearchBar from '../../../../components/Layout/MasterSearchBar';
import AddFindingModal from '../../../../components/AddFindingModal';
import AttributesRail from '../../../../components/Layout/AttributesRail';
import MoreInfoPopOver from '../../../../components/MoreInfoPopOver';
import { sortFindingsOptions } from '../../../../components/FindingsTable';
import { CONFIG } from '../../../../config';

const initialSortBy = sortFindingsOptions[0];

export default function InventoryModelFindings() {
  const { inventoryModel } = useContext(InventoryModelContext);
  const [findings, setFindings] = useState<TFinding[]>([]);

  let { isLoading, refetch } = useQuery(
    ['findings', inventoryModel?.cuid],
    async () => {
      const filters = {
        inventory_models: [inventoryModel!.cuid],
        include_archived_models: true,
      };
      return await API.GetFindings(1, 100, filters, initialSortBy);
    },
    {
      onSuccess: data => setFindings(data.results),
    },
  );

  return (
    <RecordDetailsPage
      right={
        <AttributesRail>
          <AddFindingModal onFindingAdded={() => refetch()} />
        </AttributesRail>
      }
    >
      <VStack w={'full'}>
        <HStack w={'full'}>
          <MasterSearchBar />
        </HStack>
        <MasterHeader>
          <HStack gap={0}>
            <MasterStatusBar
              title={'Model Findings'}
              item={inventoryModel}
              moreInfoPopOver={
                <MoreInfoPopOver
                  title="Model Specific Findings"
                  description="View and update your findings associated with this model. For help adding a finding, refer to our guide."
                  link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-validation/add-manage-model-findings.html`}
                  placement="right-end"
                />
              }
            />
          </HStack>
        </MasterHeader>
      </VStack>
      <LoadingContainer isLoading={isLoading}>
        {findings && findings.length > 0 ? (
          <FindingsList
            findings={findings}
            onFindingDeleted={() => {}}
            onFindingDeleteError={() => {}}
            inventoryModel={inventoryModel}
          />
        ) : (
          <Center>
            <EmptyStateDisplay variant="no-reports">
              <Heading as={'h5'}>No findings yet.</Heading>
              <Text align={'center'} mb={8}>
                No significant findings have been identified in this model's
                documentation.
              </Text>
              <AddFindingModal
                buttonBoxProps={{ position: 'relative' }}
                onFindingAdded={() => refetch()}
              />
            </EmptyStateDisplay>
          </Center>
        )}
        <Copyright />
      </LoadingContainer>
    </RecordDetailsPage>
  );
}
