import { useQuery } from 'react-query';
import API from '../../api/API';
import { ContentPageH2 } from '../Layout';
import {
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  useColorModeValue,
  HStack,
  useToken,
  Heading,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Label,
  ReferenceLine,
  Legend,
} from 'recharts';
import ChartTooltip from '../ChartTooltip';
import { displayFormatedDateAndTime } from '../../utils';
import { SectionContentsProps } from '../Layout/DocumentationPage';
import { useContext, useEffect, useRef, useState } from 'react';
import ConfirmationAlert from '../ConfirmationAlert';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import _ from 'lodash';
import { LoadingContainer } from '../LoadingContainer';
import { TUnitMetric } from '../../models/unit_metric';
import AvatarProxy from '../AvatarProxy';
import InventoryModelContext from '../../contexts/InventoryModel';
import BlockWrapper from './BlockWrapper';
import {
  schemePaired, // only 12 colors, for more colors use schemeTableau20
} from 'd3-scale-chromatic';
import { ModelDocumentTypeEnum } from '../../models/model_document';
import { CONFIG } from '../../config';

export default function UnitMetricContent({
  contents,
  removeBlock,
  readOnly,
}: SectionContentsProps) {
  const { content_id, content_type } = contents;
  const { inventoryModel } = useContext(InventoryModelContext);
  const [isFocused, setIsFocused] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const unitMetricContentRef = useRef(null);
  const [inView, setInView] = useState(false);

  const { data, isLoading, isError } = useQuery(
    ['unit-metrics', content_id],
    async () => {
      const response = await API.GetUnitMetricsValuesForKey(
        inventoryModel!.cuid,
        content_id,
      );

      return response.results.map((result: TUnitMetric) => ({
        ...result,
        value: Math.round(result.value * 10000) / 10000,
      }));
    },
    {
      enabled: !!inventoryModel?.cuid && inView,
      retry: false,
    },
  );

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && removeBlock) {
      setIsFocused(false);
      removeBlock(contents);
    }
    setConfirmDelete(false);
  };

  const handleClickOutside = (e: any) => {
    if (
      unitMetricContentRef.current &&
      !(unitMetricContentRef.current as any).contains(e.target)
    ) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [brandColor] = useToken('colors', ['brand.base']);

  const findThresholds = () => {
    if (!data) return undefined;

    // Explicitly sort by created_at in descending order to get the most recently logged thresholds
    // The backend returns data sorted by recorded_at.asc() for chronological display,
    // but thresholds should reflect the most recent configuration (by created_at)
    // Example: A metric logged today with recorded_at=yesterday should use today's thresholds
    const sortedData = [...data].sort((a, b) => b.created_at - a.created_at);

    // Get the most recent item by created_at
    const mostRecentItem = sortedData[0];

    // Return undefined if most recent item has no thresholds or empty thresholds
    if (
      !mostRecentItem.thresholds ||
      Object.keys(mostRecentItem.thresholds).length === 0
    ) {
      return undefined;
    }

    return mostRecentItem.thresholds;
  };

  const getThresholdColor = (
    value: number,
    thresholds: { [key: string]: number },
  ) => {
    // Use schemePaired - a qualitative scheme with 12 colors
    const colors = schemePaired;

    // Instead of interpolating, just assign colors based on index
    const values = Object.values(thresholds);
    const sortedValues = [...values].sort((a, b) => a - b);
    const valueIndex = sortedValues.indexOf(value);

    // Use modulo to handle cases with more thresholds than colors
    return colors[valueIndex % colors.length];
  };

  const getLegendContent = (props: any) => {
    const thresholds = findThresholds();
    if (!thresholds) return null;

    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {Object.entries(thresholds).map(([key, value]) => (
          <Box key={key} display="flex" alignItems="center" mr={4}>
            <Box
              w="12px"
              h="2px"
              bg={getThresholdColor(value, thresholds)}
              mr={2}
            />
            <Text fontSize="12px" color={getThresholdColor(value, thresholds)}>
              {key}
            </Text>
          </Box>
        ))}
      </Box>
    );
  };

  const getReferenceLines = () => {
    const thresholds = findThresholds();
    if (!thresholds || Object.keys(thresholds).length === 0) {
      return null;
    }
    return Object.entries(thresholds).map(([key, value]) => (
      <ReferenceLine
        key={key}
        y={value}
        stroke={getThresholdColor(value, thresholds)}
        strokeWidth={3}
        label={{
          value: `${key}: ${value}`,
          position: 'right',
          fill: getThresholdColor(value, thresholds),
          fontSize: 12,
        }}
      />
    ));
  };

  if (!isLoading && (isError || data?.length === 0)) {
    return (
      <>
        <ContentPageH2>
          {<Text>{_.startCase(content_id.split('.').pop())}</Text>}
        </ContentPageH2>
        <EmptyStateDisplay variant="no-activity">
          <Heading as={'h5'}>
            The data associated with this Metric Over Time Block (
            {<code>{content_id}</code>}) is missing.
          </Heading>
          <Text align={'center'}>
            To populate this content block please make sure to run the
            associated documentation test suite.
          </Text>
          <Text align={'center'} pb={10}>
            Please refer to{' '}
            <a href={CONFIG.VALIDMIND_DOCS_URL} target="_blank">
              ValidMind's documentation
            </a>{' '}
            for more information.
          </Text>
        </EmptyStateDisplay>
      </>
    );
  }
  return (
    <LoadingContainer isLoading={isLoading}>
      <ConfirmationAlert
        open={confirmDelete}
        title={`Remove "${data && data.length && data[0].name}" Block`}
        dialogBody={`Are you sure you'd like to remove "${
          data && data.length && data[0].name
        }" block?`}
        onConfirm={onDeleteConfirmed}
      />
      <Box position={'relative'} flex={1} display="flex">
        <BlockWrapper
          // For now unit metrics are only supported in the monitoring document
          documentType={ModelDocumentTypeEnum.monitoring}
          contentId={content_id}
          contentType={content_type}
          readOnly={readOnly}
          setIsInView={setInView}
          showDeleteConfirmation={() => setConfirmDelete(true)}
        >
          <VStack
            p={2}
            border={'1px solid'}
            borderColor={isFocused ? 'brand.base' : 'transparent'}
            bg={
              isFocused
                ? useColorModeValue('neutral.50', 'neutral.850')
                : 'transparent'
            }
            borderRadius={'md'}
            transition={'all .5s ease-in-out'}
            _hover={
              isFocused
                ? {
                    borderColor: 'brand.base',
                  }
                : {
                    borderColor: useColorModeValue(
                      'neutral.200',
                      'neutral.700',
                    ),
                  }
            }
            onClick={() => {
              if (readOnly) return;
              setIsFocused(true);
            }}
            maxH="500px"
            height="500px"
            overflow="hidden"
            w="full"
            display="flex"
            alignItems="flex-start"
            ref={unitMetricContentRef}
          >
            <ContentPageH2>
              {data && data.length && <Text>{data[0].name}</Text>}
            </ContentPageH2>
            <VStack
              alignItems="flex-start"
              flex={1}
              flexGrow={1}
              w="full"
              overflow="hidden"
            >
              <Tabs
                isFitted
                overflowY="auto"
                display="flex"
                flexDirection="column"
                w="full"
                h="full"
                colorScheme="brand"
              >
                <TabList flex={0}>
                  <Tab flex={0}>Chart</Tab>
                  <Tab flex={0}>Data</Tab>
                </TabList>
                <TabPanels w="full" h="full" overflowY="auto" display="flex">
                  <TabPanel padding={0} paddingTop={4} w="full" h="full">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend content={getLegendContent} />
                        {getReferenceLines()}
                        <YAxis
                          tickCount={5}
                          label={{
                            dx: -10,
                            angle: -90,
                            style: {
                              fontSize: '14px',
                              fontWeight: 'normal',
                            },
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                          axisLine={false}
                          tickLine={false}
                          domain={[
                            (dataMin: number) => {
                              const thresholds = findThresholds();
                              // Get minimum value from all thresholds
                              const minThreshold = thresholds
                                ? Math.min(...Object.values(thresholds))
                                : dataMin * 0.9;
                              return Math.min(dataMin * 0.9, minThreshold);
                            },
                            (dataMax: number) => {
                              const thresholds = findThresholds();
                              // Get maximum value from all thresholds
                              const maxThreshold = thresholds
                                ? Math.max(...Object.values(thresholds))
                                : dataMax * 1.1;
                              return Math.max(dataMax * 1.1, maxThreshold);
                            },
                          ]}
                          tickFormatter={v => v.toFixed(2)}
                        >
                          <Label
                            style={{
                              textAnchor: 'middle',
                            }}
                            angle={270}
                            value={data && data.length ? data[0].name : ''}
                            dx={-25}
                          />
                        </YAxis>
                        <XAxis
                          dataKey="recorded_at"
                          tickFormatter={v => displayFormatedDateAndTime(v)}
                          display="none"
                        >
                          <Label
                            style={{
                              textAnchor: 'middle',
                            }}
                            value={'Recorded At (Time)'}
                          />
                        </XAxis>
                        <RechartsTooltip
                          content={({ payload, ...params }) => {
                            if (!payload || payload.length === 0) {
                              return null;
                            }
                            const currentPayload = payload[0];
                            const payloadParams =
                              currentPayload.payload.params || {};
                            let extraContent = null;

                            const paramKeys = Object.keys(payloadParams || {});

                            if (paramKeys.length > 0) {
                              extraContent = (
                                <VStack w={'full'}>
                                  <Text fontWeight="bold">Params</Text>
                                  {
                                    <VStack alignItems="flex-start">
                                      {paramKeys.map((key, i) => (
                                        <HStack key={i}>
                                          <Text>{key}:</Text>
                                          <Text>{payloadParams[key]}</Text>
                                        </HStack>
                                      ))}
                                    </VStack>
                                  }
                                </VStack>
                              );
                            }
                            return (
                              <ChartTooltip
                                {...params}
                                payload={payload}
                                showValue={true}
                                labelFormatter={(v: any) =>
                                  displayFormatedDateAndTime(v)
                                }
                                extraContent={extraContent}
                              />
                            );
                          }}
                          wrapperStyle={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                            color: 'black',
                            zIndex: 1000,
                          }}
                          labelFormatter={v => displayFormatedDateAndTime(v)}
                        />
                        <Line
                          type="monotone"
                          dataKey="value"
                          fill={brandColor}
                          stroke={brandColor}
                          activeDot={{ r: 6 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </TabPanel>
                  <TabPanel
                    padding={0}
                    paddingTop={4}
                    w="full"
                    h="full"
                    overflowY="auto"
                  >
                    <Box overflowY="scroll" height="100%" w="full">
                      <Table w="full">
                        <Thead>
                          <Tr>
                            <Th width="30%">Recorded At</Th>
                            <Th width="17.5%">Updated By</Th>
                            <Th width="17.5%">Value</Th>
                            <Th width="17.5%">Thresholds</Th>
                            <Th width="17.5%">Params</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data &&
                            data?.map((item: TUnitMetric, i) => (
                              <Tr key={i}>
                                <Td>
                                  <Text fontSize="small">
                                    {displayFormatedDateAndTime(
                                      item.recorded_at,
                                    )}
                                  </Text>
                                </Td>
                                <Td>
                                  {item.user && (
                                    <Tag
                                      key={`data-${i}-user-${item.user}`}
                                      size={'md'}
                                    >
                                      <AvatarProxy
                                        src={item.user.picture}
                                        size="xs"
                                        name={item.user.name}
                                        ml={-2}
                                        mr={2}
                                      />
                                      <TagLabel>{item.user.name}</TagLabel>
                                    </Tag>
                                  )}
                                </Td>
                                <Td fontSize="small">
                                  <Text>{item.value}</Text>
                                </Td>
                                <Td fontSize="small">
                                  <VStack align="start" spacing={1}>
                                    {item.thresholds &&
                                      Object.entries(item.thresholds).map(
                                        ([key, value]) => (
                                          <Text key={key}>
                                            {key}: {value}
                                          </Text>
                                        ),
                                      )}
                                  </VStack>
                                </Td>
                                <Td fontSize="small">
                                  <Text>
                                    {item.params &&
                                      Object.keys(item.params).map((key, i) => (
                                        <Text key={i}>
                                          {key}: {item.params[key]}
                                        </Text>
                                      ))}
                                  </Text>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </VStack>
        </BlockWrapper>
      </Box>
    </LoadingContainer>
  );
}
