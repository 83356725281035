import API from '../../../api/API';
import { useQuery } from 'react-query';
import {
  Box,
  HStack,
  Heading,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { ContentPageTitle } from '../../../components/Layout';
import Breadcrumbs from '../../../components/Breadcrumbs';
import WorkflowsPage from './pages/WorkflowPage';
import { useContext, useEffect } from 'react';
import SidebarContext from '../../../contexts/SidebarContext';
import { Link as RouterLink } from 'react-router-dom';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EllipsisVerticalIcon, EyeIcon } from '@heroicons/react/24/outline';
import { CONFIG } from '../../../config';

function WorkflowList() {
  const navigate = useNavigate();
  const { data: workflows2, isLoading } = useQuery(
    ['organizations', 'workflows2'],
    async () => {
      return API.GetWorkflows();
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <LoadingContainer isLoading={isLoading}>
        <Box>
          <Breadcrumbs />
          <ContentPageTitle>
            Workflows
            <MoreInfoPopOver
              title="Configure Workflows"
              link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-workflows/set-up-model-workflows.html`}
              placement="right-end"
              iconProps={{
                ml: 2,
              }}
            />
          </ContentPageTitle>
        </Box>
        <Text>
          Manage lifecycle processes within your ValidMind setup using workflows
          and transitions. Workflows can be configured to match your
          organizational needs for overseeing model development, validation, or
          implementation activities.
        </Text>
        <Stack w={'full'}>
          {workflows2 && (
            <VStack align={'stretch'} w={'full'} gap={1}>
              {workflows2.results
                // filter out project workflows
                .filter(wf => wf.trigger_id !== 'Project.created')
                .map(workflow => (
                  <LinkBox
                    p={4}
                    gap={4}
                    rounded={'md'}
                    border={'1px solid'}
                    transition={'all 0.3s ease-in-out'}
                    data-testid="workflow-item-link"
                    _hover={{
                      bg: useColorModeValue(
                        'brandSecondary.25',
                        'brandSecondary.950',
                      ),
                      borderColor: useColorModeValue(
                        'brandSecondary.100',
                        'brandSecondary.800',
                      ),
                      color: useColorModeValue('inherit', 'brandSecondary.25'),
                    }}
                    borderColor={useColorModeValue(
                      'neutral.200',
                      'neutral.800',
                    )}
                    role="group"
                  >
                    <HStack alignItems={'flex-start'}>
                      <VStack alignItems={'flex-start'} gap={0}>
                        <Heading as={'h4'}>
                          <LinkOverlay
                            as={RouterLink}
                            to={`/settings/workflows/${workflow.cuid}/latest`}
                          >
                            {workflow.trigger_id === 'InventoryModel.created' &&
                              'Inventory Model'}
                            {workflow.trigger_id === 'Project.created' &&
                              'Model Documentation'}
                          </LinkOverlay>
                        </Heading>
                        <Text>{workflow.title}</Text>
                      </VStack>
                      <Spacer />
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant="ghost"
                          aria-label="Options"
                          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                          display={'none'}
                          _groupHover={{
                            display: 'block',
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<Icon as={EyeIcon} boxSize={5} />}
                            onClick={() =>
                              navigate(
                                `/settings/workflows/${workflow.cuid}/latest`,
                              )
                            }
                          >
                            See Details
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </LinkBox>
                ))}
            </VStack>
          )}
        </Stack>
      </LoadingContainer>
    </VStack>
  );
}
export default function Workflows() {
  const { setInSettings } = useContext(SidebarContext);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<WorkflowList />} />
      <Route path=":workflowCuid/:version" element={<WorkflowsPage />} />
    </Routes>
  );
}
