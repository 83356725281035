import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Spacer,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
} from '@chakra-ui/react';
import { useState } from 'react';
import AdminAPI from '../../api/AdminAPI';
import { LoadingContainer } from '../../components/LoadingContainer';
import { useAuth } from 'react-oidc-context';

type DiagnosticsState = {
  isRunning: boolean;
  runId: string | null;
  startTime: string | null;
  components: Array<{
    component: string;
    status: string;
  }>;
};

const Tools = () => {
  const toast = useToast();
  const [showClaims, setShowClaims] = useState(false);
  const [diagnosticsState, setDiagnosticsState] = useState<DiagnosticsState>({
    isRunning: false,
    runId: null,
    startTime: null,
    components: [],
  });
  const { user } = useAuth();

  const pollDiagnostics = async (runId: string) => {
    let attempts = 0;
    const maxAttempts = 10;
    const interval = setInterval(async () => {
      attempts++;
      try {
        const run = await AdminAPI.GetDiagnosticsRun(runId);
        if (run) {
          setDiagnosticsState(prev => ({
            ...prev,
            components: run.diagnostics.map(d => ({
              component: d.component,
              status: d.status,
            })),
          }));
        }
      } catch (error) {
        console.error('Error polling diagnostics:', error);
      }

      if (attempts >= maxAttempts) {
        clearInterval(interval);
        setDiagnosticsState(prev => ({
          ...prev,
          isRunning: false,
          components: prev.components.map(comp => ({
            ...comp,
            status: comp.status === 'started' ? 'timeout' : comp.status,
          })),
        }));
      }
    }, 1000);
  };

  const handleActionClick = async (action: string) => {
    try {
      if (action === 'claims') {
        setShowClaims(!showClaims);
        return;
      }
      if (action === 'diagnostics') {
        setDiagnosticsState({
          isRunning: true,
          runId: null,
          startTime: new Date().toLocaleString(),
          components: [],
        });
        const diagnosticsId = await AdminAPI.CreateDiagnosticsRun();
        setDiagnosticsState(prev => ({
          ...prev,
          runId: diagnosticsId,
        }));
        pollDiagnostics(diagnosticsId);
        toast({
          title: 'Diagnostics run started',
          description: `Diagnostics ID: ${diagnosticsId}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await AdminAPI.PatchRBAC(action);
        toast({
          title: `${action} action executed successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: unknown) {
      const axiosError = error as any;
      toast({
        title: `Failed to execute ${action} action.`,
        description:
          axiosError?.response?.data?.message || 'An error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      if (action === 'diagnostics') {
        setDiagnosticsState({
          isRunning: false,
          runId: null,
          startTime: null,
          components: [],
        });
      }
    }
  };

  const actions = [
    {
      title: 'Refresh RBAC',
      description: 'Refresh Role-Based Access Control data.',
      action: 'refresh',
      enabled: true,
    },
    {
      title: 'Rebuild Permissions (Actions Update)',
      description: 'Recreate permissions.',
      action: 'rebuild',
      enabled: false,
    },
    {
      title: 'Run Diagnostics',
      description: 'Run system diagnostics and health checks.',
      action: 'diagnostics',
      enabled: !diagnosticsState.isRunning,
      showResults: true,
    },
    {
      title: 'View Token Claims',
      description: 'Display current user claims from the ID token.',
      action: 'claims',
      enabled: true,
      showResults: true,
    },
  ];

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'ok':
        return 'green.500';
      case 'started':
        return 'blue.500';
      case 'timeout':
        return 'orange.500';
      default:
        return 'red.500';
    }
  };

  const renderClaimsTable = () => {
    if (!user || !showClaims) return null;

    return (
      <Box mt={2} p={4} borderWidth="1px" borderRadius="lg">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Claim</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(user.profile).map(([key, value]) => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{JSON.stringify(value)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };

  return (
    <Box p={8}>
      <Heading mb={4}>Admin Tools</Heading>
      <LoadingContainer isLoading={false}>
        <VStack spacing={4} align="stretch">
          {actions.map(
            ({ title, description, action, enabled, showResults }, index) => (
              <Box key={index}>
                <HStack
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  boxShadow="sm"
                >
                  <Box>
                    <Text fontSize="md" fontWeight="medium">
                      {title}
                    </Text>
                    <Text color="gray.500">{description}</Text>
                  </Box>
                  <Spacer />
                  {action === 'claims' ? (
                    <Button
                      colorScheme="blue"
                      onClick={() => handleActionClick(action)}
                      isDisabled={!enabled || !user}
                    >
                      {showClaims ? 'Hide' : 'Show'}
                    </Button>
                  ) : (
                    <Button
                      colorScheme="blue"
                      onClick={() => handleActionClick(action)}
                      isDisabled={!enabled}
                    >
                      Execute
                    </Button>
                  )}
                </HStack>
                {showResults && (
                  <>
                    {action === 'claims' && renderClaimsTable()}
                    {action === 'diagnostics' &&
                      (diagnosticsState.isRunning ||
                        diagnosticsState.components.length > 0) && (
                        <Box mt={2} p={4} borderWidth="1px" borderRadius="lg">
                          {diagnosticsState.isRunning &&
                          diagnosticsState.components.length === 0 ? (
                            <Text color="gray.500">
                              Waiting for diagnostics results...
                            </Text>
                          ) : (
                            diagnosticsState.components.length > 0 && (
                              <>
                                <HStack mb={3} spacing={2}>
                                  <Text color="gray.500">
                                    Started: {diagnosticsState.startTime}
                                  </Text>
                                  <Badge
                                    colorScheme={
                                      diagnosticsState.isRunning
                                        ? 'green'
                                        : 'orange'
                                    }
                                    px={2}
                                    py={1}
                                    borderRadius="full"
                                  >
                                    {diagnosticsState.isRunning
                                      ? 'Running'
                                      : 'Finished'}
                                  </Badge>
                                </HStack>
                                <Table size="sm">
                                  <Thead>
                                    <Tr>
                                      <Th>Component</Th>
                                      <Th>Status</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {diagnosticsState.components.map(
                                      (comp, i) => (
                                        <Tr key={i}>
                                          <Td>{comp.component}</Td>
                                          <Td
                                            color={getStatusColor(comp.status)}
                                          >
                                            {comp.status}
                                          </Td>
                                        </Tr>
                                      ),
                                    )}
                                  </Tbody>
                                </Table>
                              </>
                            )
                          )}
                        </Box>
                      )}
                  </>
                )}
              </Box>
            ),
          )}
        </VStack>
      </LoadingContainer>
    </Box>
  );
};

export default Tools;
