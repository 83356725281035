import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { LoadingContainer } from '../components/LoadingContainer';
import Organizations from './pages/Organizations';
import NotFound from './pages/NotFound';
import { useState, useEffect } from 'react';
import OrganizationDefaults from './pages/OrganizationDefaults';
import Users from './pages/Users';
import Tools from './pages/Tools';
import { useAuth } from 'react-oidc-context';
import { useCurrentAdminUser } from '../hooks/admin';
import { setTokenRetrievalMethod } from '../api/AdminAPI';
import ViewOrganization from './pages/ViewOrganization';
import ViewUser from './pages/ViewUser';
import Settings from './pages/Settings';
import AdminLayout from '../components/Layout/AdminLayout';
import { Box } from '@chakra-ui/react';

function AdminApp() {
  const {
    isLoading: isUserLoading,
    isAuthenticated,
    user,
    signinRedirect,
  } = useAuth();

  // Use tri-state: 'pending', 'authorized', or 'unauthorized'
  const [authorizationStatus, setAuthorizationStatus] = useState<'pending' | 'authorized' | 'unauthorized'>('pending');
  const [isTokenMethodSet, setIsTokenMethodSet] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    if (!isUserLoading && isAuthenticated) {
      setTokenRetrievalMethod(() => user?.access_token);
      setIsTokenMethodSet(true);
    }
  }, [user, isAuthenticated, isUserLoading]);

  const { data: currentAdminUser, isLoading: isAdminUserLoading } = useCurrentAdminUser(isTokenMethodSet);

  useEffect(() => {
    if (!isUserLoading && !isAdminUserLoading && isTokenMethodSet) {
      if (isAuthenticated) {
        if (currentAdminUser?.role !== null && currentAdminUser?.role !== undefined) {
          setAuthorizationStatus('authorized');
        } else {
          setAuthorizationStatus('unauthorized');
        }
      } else {
        signinRedirect({ state: { returnTo: window.location.pathname } });
        setAuthorizationStatus('unauthorized');
      }
      setIsCheckingAuth(false);
    }
  }, [isUserLoading, isAdminUserLoading, isTokenMethodSet, isAuthenticated, currentAdminUser, user]);

  // Wait until all auth/user data is resolved.
  if (isCheckingAuth || isUserLoading || isAdminUserLoading || !isTokenMethodSet || authorizationStatus === 'pending') {
    return (
      <LoadingContainer isLoading={true}>
        <></>
      </LoadingContainer>
    );
  }

  if (authorizationStatus === 'authorized') {
    return (
      <AdminLayout>
        <Routes>
          <Route element={<Outlet />}>
            <Route path="/" element={<Navigate to="/admin/organizations" replace />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/organizations/:cuid" element={<ViewOrganization />} />
            <Route path="/organization-defaults" element={<OrganizationDefaults />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:cuid" element={<ViewUser />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AdminLayout>
    );
  } else {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <Box p={8}>
              <NotFound />
            </Box>
          }
        />
      </Routes>
    );
  }
}

export default AdminApp;
