import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Text, VStack, Box, Stack, Heading } from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import {
  TPermissionWithRoles,
  TPermissionWithRolesMap,
} from '../../../models/role';
import PermissionsList from '../../../components/PermissionsList';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { CONFIG } from '../../../config';

export default function Permissions() {
  const { setInSettings } = useContext(SidebarContext);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const { data: permissionsOverview, refetch } = useQuery(
    ['permissions'],
    async () => {
      return await API.GetPermissionsOverview();
    },
  );

  const filterByScope = (
    categories: TPermissionWithRolesMap,
    scope: string | null,
  ) => {
    let filtered = _.cloneDeep(categories);

    Object.keys(categories).forEach(categoryKey => {
      const category = categories[categoryKey].filter(
        (a: TPermissionWithRoles) => a.scope === scope,
      );
      filtered[categoryKey] = category;
    });

    return filtered;
  };

  let filteredPermissionsCategories = filterByScope(
    permissionsOverview?.categories || {},
    tabIndex === 0 ? '' : 'Model',
  );

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Permissions
          <MoreInfoPopOver
            title="User Permissions"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/manage-permissions.html`}
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        Below are a list of actions as well as the Roles who have authorization
        to perform them.
      </Text>
      <Stack width={'full'} gap={4}>
        <VStack gap={4} alignItems="flex-start">
          <Heading as="h3">All Permissions</Heading>
          <VStack width={'full'} gap={4} alignItems="flex-start">
            <PermissionsList
              categories={filteredPermissionsCategories}
              mode="view"
              permissionsScope=""
              enableRoleManagement
              rolesChanged={refetch}
            />
          </VStack>
        </VStack>
      </Stack>
    </VStack>
  );
}
