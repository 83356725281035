import { useContext, useEffect, useState } from 'react';
import InventoryModelContext from '../../../../contexts/InventoryModel';
import MasterStatusBar from '../../../../components/Layout/MasterStatusBar';
import MasterHeader from '../../../../components/Layout/MasterHeader';
import { useQuery } from 'react-query';
import API from '../../../../api/API';
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
import { EventFilters, TEvent } from '../../../../models/event';
import { Copyright } from '../../../../components/Copyright';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import MasterSearchBar from '../../../../components/Layout/MasterSearchBar';
import MoreInfoPopOver from '../../../../components/MoreInfoPopOver';
import EventList from '../../../../components/EventList';
import { CONFIG } from '../../../../config';

export default function InventoryModelActivity() {
  const { inventoryModel } = useContext(InventoryModelContext);
  const [events, setEvents] = useState<TEvent[]>([]);
  const [tabs, setTabs] = useState(['All']);
  const [tabIndex, setTabIndex] = useState(tabs.indexOf('All'));
  const [showOnlyMentions, setShowOnlyMentions] = useState(true);
  const [filtersWithTabs, setFiltersWithTabs] = useState<EventFilters>();

  const { isLoading } = useQuery(
    ['inventory-model', inventoryModel?.cuid, filtersWithTabs],
    async () => {
      const filters = {
        inventory_models: [inventoryModel?.cuid!],
        ...filtersWithTabs,
      };
      return await API.GetEvents(1, 200, filters);
    },
    {
      onSuccess: data => setEvents(data.results),
      onError: err => {
        // track errors
      },
    },
  );

  useEffect(() => {
    let withTabs: EventFilters = {
      tab: tabs[tabIndex],
      ...(tabs[tabIndex] === 'Comments' && { only_mentions: showOnlyMentions }),
    };

    setFiltersWithTabs(withTabs);
  }, [tabIndex, showOnlyMentions]);

  useEffect(() => {
    setTabs([
      'All',
      'Comments',
      'Status Updates',
      'Model Updates',
      'Test Results',
    ]);
  }, []);

  return (
    <RecordDetailsPage>
      <VStack w={'full'}>
        <HStack w={'full'}>
          <MasterSearchBar />
        </HStack>
        <MasterHeader>
          <HStack gap={0}>
            <MasterStatusBar
              title={'Model Activity'}
              item={inventoryModel}
              moreInfoPopOver={
                <MoreInfoPopOver
                  title="Model Specific Activity"
                  description="View all the information events associated with a specific model."
                  link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-documentation/view-documentation-activity.html`}
                  placement="right-end"
                />
              }
            />
          </HStack>
        </MasterHeader>
      </VStack>

      <>
        <Collapse
          in={true}
          animateOpacity
          style={{
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
          }}
        >
          <Box maxH="100%">
            <EventList
              isLoading={isLoading}
              events={events}
              tabs={tabs}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              showOnlyMentions={showOnlyMentions}
              setShowOnlyMentions={setShowOnlyMentions}
              variant="user-summary"
            />
          </Box>
        </Collapse>
      </>
      <Copyright />
    </RecordDetailsPage>
  );
}
