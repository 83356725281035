import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/API';
import { ModelDocumentTypeEnum } from '../../models/model_document';

export default function useTestResult(
  test_name: string,
  content_type?: ModelDocumentTypeEnum,
  enabled: boolean = true,
  fields: string[] = ['all'],
) {
  // Always scope the test_result to a inventory model identifier
  const { id } = useParams();
  const queryResponse = useQuery(
    ['test_results', test_name, id, fields],
    async () => {
      const response = await API.GetTestResultForInventoryModel(
        id!,
        test_name,
        content_type,
        fields,
      );
      // Treat 204 responses as null data
      if (!response) return null;
      return response;
    },
    {
      enabled,
      retry: false,
    },
  );

  return { testResult: queryResponse.data, ...queryResponse };
}
